import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const register = createAsyncThunk('registration/register', async (formData) => {
  try {
    const { data } = await api.registration.register(formData);
    toast.success(data.message);
    return data.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const getDataBusiness = createAsyncThunk(
  'registration/dataBusiness',
  async () => {
    try {
      const { data } = await api.registration.getBusiness();
      return data.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const verifyAccount = createAsyncThunk(
  'registration/verifyAccount',
  async (verification_code) => {
    try {
      const { data } = await api.registration.verifyAccount({
        verification_code,
      });
      toast.success('Account verified');
      return data.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  register,
  getDataBusiness,
  verifyAccount,
};

export { thunks };

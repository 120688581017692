import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getCourierInfo = createAsyncThunk(
  'courierTracking/getCourierInfo',
  async (params) => {
    try {
      const { data } = await api.courierTracking.getCourierInfo(params);
      return data.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getCourierInfo,
};

export { thunks };

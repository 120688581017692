import { WEB_API_ROUTES } from 'api/api-routes';
import http from 'api/http';

export const restaurantMenu = {
  getMenu() {
    return http.get(WEB_API_ROUTES.user.menu.getMenu);
  },

  createMenu() {
    return http.post(WEB_API_ROUTES.user.menu.getMenu);
  },

  getCategoryMenu(id) {
    return http.get(
      WEB_API_ROUTES.user.menu.getCategoryMenu.replace('{menu_category}', id)
    );
  },

  putCategoryMenu(formData) {
    const { id, data } = formData;
    return http.put(
      WEB_API_ROUTES.user.menu.updateCategory.replace('{menu_category}', id),
      data
    );
  },

  createCategory(formData) {
    const { data, id } = formData;
    return http.post(
      WEB_API_ROUTES.user.menu.createCategory.replace('{menu}', id),
      data
    );
  },

  deleteCategory(id) {
    return http.delete(
      WEB_API_ROUTES.user.menu.deleteCategory.replace('{menu_category}', id)
    );
  },

  createDish(formData) {
    const { data, id } = formData;
    return http.post(
      WEB_API_ROUTES.user.menu.createDish.replace('{menu_category}', id),
      data
    );
  },

  updateDish(formData) {
    const { data, id } = formData;
    return http.post(
      WEB_API_ROUTES.user.menu.deleteDish.replace('{menu_dish}', id),
      data
    );
  },

  deleteDish(id) {
    return http.delete(
      WEB_API_ROUTES.user.menu.deleteDish.replace('{menu_dish}', id)
    );
  },

  getDish(id) {
    return http.get(
      WEB_API_ROUTES.user.menu.deleteDish.replace('{menu_dish}', id)
    );
  },

  menuExport() {
    return http.post(WEB_API_ROUTES.user.menu.menuExport);
  },

  dishExport(id) {
    return http.post(
      WEB_API_ROUTES.user.menu.dishExport.replace('{menuDish}', id)
    );
  },
};

import styled from '@emotion/styled';

const Form = styled.form`
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: auto;
  padding: 60px;
  background-color: #fff;
  border: 0.5px solid #d7d7d7;
  border-radius: 20px;
  box-shadow: 0 12px 70px rgba(0, 0, 0, 0.06);
`;

const ButtonContainer = styled.div`
  max-width: 160px;
  margin: 30px auto 0;
`;

const LogoContainer = styled.div`
  margin-block: 0 50px;
`;
const Layout = styled.div`
  display: flex;
  height: 100vh;
  padding: 16px;
  overflow-y: auto;
  background: ${(props) => props.theme.background};
`;

const ForgotPassword = styled.div`
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  margin-top: 40px;
  cursor: pointer;
`;

export { Form, LogoContainer, ButtonContainer, Layout, ForgotPassword };

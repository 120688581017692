import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  settingsCourier: [],
  activeSettings: false,
  settingsOfFlag: false,
  activeTab: 'NEAREST',
  settingStatus: status.IDLE,
};

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    SET_ACTIVE_TAB: (state, { payload }) => {
      state.activeTab = payload;
    },
    SET_ACTIVE_SETTINGS: (state, { payload }) => {
      state.activeSettings = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getSettings.pending, (state) => {
        state.settingStatus = status.PENDING;
      })
      .addCase(thunks.getSettings.fulfilled, (state, { payload }) => {
        const { courierSetting, activeSettingId } = payload;
        if (courierSetting.length) {
          const findActiveTab = courierSetting.find(
            (item) => item.id === activeSettingId
          );

          if (findActiveTab) {
            state.activeSettings = true;
            state.settingsOfFlag = true;
            state.activeTab = findActiveTab.settingName;
          }

          state.settingsCourier = courierSetting;
        }

        state.settingStatus = status.SUCCESS;
      })
      .addCase(thunks.getSettings.rejected, (state) => {
        state.settingStatus = status.FAIL;
      })
      .addCase(thunks.addSettings.pending, (state) => {
        state.settingStatus = status.PENDING;
      })
      .addCase(thunks.addSettings.fulfilled, (state, { payload }) => {
        state.settingsCourier = [...state.settingsCourier, payload.data];
        state.settingStatus = status.SUCCESS;
      })
      .addCase(thunks.addSettings.rejected, (state) => {
        state.settingStatus = status.FAIL;
      })
      .addCase(thunks.updateSettings.pending, (state) => {
        state.settingStatus = status.PENDING;
      })
      .addCase(thunks.updateSettings.fulfilled, (state, { payload }) => {
        const { data } = payload;
        const filter = state.settingsCourier.filter(
          (item) => item.id !== data.id
        );
        state.settingsCourier = [...filter, data];
        state.settingsOfFlag = true;
        state.settingStatus = status.SUCCESS;
      })
      .addCase(thunks.updateSettings.rejected, (state) => {
        state.settingStatus = status.FAIL;
      })
      .addCase(thunks.disableSettings.pending, (state) => {
        state.settingStatus = status.PENDING;
      })
      .addCase(thunks.disableSettings.fulfilled, (state) => {
        state.settingsOfFlag = false;
        state.settingStatus = status.SUCCESS;
      })
      .addCase(thunks.disableSettings.rejected, (state) => {
        state.settingStatus = status.FAIL;
        state.settingsOfFlag = false;
      });
  },
});

const settings = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { settings };
export default slice.reducer;

import { toast } from 'react-toastify';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getMerchantInfo = createAsyncThunk(
  'merchant/getMerchantData',
  async () => {
    try {
      const { data } = await api.user.getMerchantInfo();
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const editMerchantInfo = createAsyncThunk(
  'merchant/editMerchantInfo',
  async ({ id, formData }) => {
    try {
      const { data } = await api.user.editMerchantInfo({ id, formData });
      toast.success(data.message);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const editDeliveryInfo = createAsyncThunk(
  'merchant/editDeliveryInfo',
  async ({ id, formData }) => {
    try {
      const { data } = await api.user.editDeliveryInfo({ id, formData });
      toast.success(data.message);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const changePassword = createAsyncThunk(
  'merchant/changePassword',
  async (formData) => {
    try {
      const { data } = await api.user.changePassword(formData);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getMerchantInfo,
  editMerchantInfo,
  editDeliveryInfo,
  changePassword,
};

export { thunks };

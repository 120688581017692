import styled from '@emotion/styled';
import { css } from '@emotion/react';

const Button = styled.button`
  position: relative;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  height: ${({ height }) => `${height}px`};
  min-width: 160px;
  padding: 12px 18px;
  font-weight: 500;
  font-size: ${(props) => props.theme.font.sizeLg};
  border-width: 2px;
  border-style: solid;
  border-radius: 10px;
  outline: none;
  overflow: hidden;
  cursor: pointer;
  -webkit-transition: background 0.5s ease-out;
  -moz-transition: background 0.5s ease-out;
  -o-transition: background 0.5s ease-out;
  transition: background 0.5s ease-out;
  ${({ css }) => css}

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 400px;
    height: 400px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    transition: 0.6s ease-in-out;
  }

  &:active:after {
    width: 0;
    height: 0;
    transform: translate(-50%, -50%);
    opacity: 1;
    visibility: visible;
    transition: 0s;
  }

  &:hover {
    background-position: 200px;
  }

  ${({ variant, theme }) => {
    switch (variant) {
      case 'outline':
        return css`
          color: ${theme.button.alternative.color};
          background-color: transparent;
          border-color: ${theme.button.alternative.borderOutlined};

          &:after {
            background-color: #f2f2f2;
          }

          &:hover {
            background-color: #f9f9f9;
          }
        `;
      case 'alternative':
        return css`
          color: ${theme.button.alternative.color};
          background: ${theme.button.alternative.bgHover};
          border-color: ${theme.button.primary.border};
          box-shadow: 4px 8px 27px rgba(36, 0, 255, 0.1),
            inset 0px -6px 14px rgba(41, 101, 255, 0.15);
          background-size: 1px 200px;

          &:after {
            background-color: #f2f2f2;
          }
        `;
      case 'alternative_delivery':
        return css`
          border-color: ${theme.gray[15]};
          background: ${theme.gray[15]};
          color: ${theme.statusChips.borderDefault};
          border-radius: 6px;

          &:after {
            background-color: ${theme.gray[1]};
          }

          path {
            fill: ${theme.statusChips.borderDefault};
          }

          &:hover {
            background: ${theme.button.alternative.color};
          }
        `;
      case 'primary':
        return css`
          color: ${theme.button.primary.color};
          background: ${theme.button.primary.bgHover};
          border-color: ${theme.button.primary.border};
          box-shadow: 6px 10px 18px rgba(118, 0, 174, 0.1),
            inset 0px -6px 14px rgba(91, 0, 206, 0.3);
          background-size: 1px 200px;

          &:after {
            background: #f2f2f2;
          }
        `;
      case 'simple':
        return css`
          color: ${theme.button.simple.color};
          background-color: ${theme.button.simple.bgLight};
          border-color: ${theme.button.primary.border};

          &:after {
            background-color: #f2f2f2;
          }

          &:hover {
            background-color: ${theme.button.simple.bgDark};
          }
        `;
      case 'simple_billing':
        return css`
          display: flex;
          justify-content: center;
          align-items: center;
          color: ${theme.button.simple.color};
          background-color: ${theme.button.simple.bgLight};
          border-color: ${theme.button.primary.border};
          border-radius: 4px;
          font-weight: 400;
          min-width: auto;
          padding: 8px 12px;
          font-size: 14px;
          line-height: 1rem;

          &:after {
            background-color: #f2f2f2;
          }

          &:hover {
            background-color: ${theme.button.simple.bgDark};
          }
        `;
      case 'simple_delivery':
        return css`
          padding: 8px 12px;
          color: ${theme.gray[9]};
          border-radius: 8px;
          background-color: transparent;
          border-color: ${theme.statusChips.iconColor};
          font-weight: 400;

          &:after {
            background-color: ${theme.gray[1]};
          }

          path {
            fill: ${theme.statusChips.iconColorDark};
          }

          &:hover {
            background-color: ${theme.btnLightBlue};
          }
        `;
      case 'text':
        return css`
          color: ${theme.button.alternative.color};
          background-color: transparent;
          border-color: transparent;

          &:after {
            background-color: #f2f2f2;
          }

          &:hover {
            background-color: #f9f9f9;
          }
        `;
      case 'default_delivery':
        return css`
          background-color: ${theme.statusChips.iconColorDark};
          color: ${theme.statusChips.borderDefault};
          font-weight: 500;
          border-color: ${theme.statusChips.iconColorDark};

          &:after {
            background-color: ${theme.gray[1]};
          }

          &:hover {
            background-color: ${theme.gray[15]};
          }
        `;
      case 'delivery_gray':
        return css`
          background-color: ${theme.gray[2]};
          font-weight: 500;
          border-color: ${theme.gray[2]};

          &:after {
            background-color: ${theme.gray[1]};
          }

          &:hover {
            background-color: ${theme.gray[15]};
          }
        `;
      default:
        return css`
          color: #ffffff;
          background: ${theme.button.primary.bgLight};
          border-color: ${theme.button.primary.border};

          &:after {
            background-color: #dfdfdf;
          }

          &:hover {
            box-shadow: 0 2px 5px 1px rgba(13, 13, 15, 0.18);
          }
        `;
    }
  }}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;

      &:after {
        display: none;
      }
    `}
  ${({ css }) => css}
`;

const ButtonChildren = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const PrependIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
`;

const AppendIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
`;

export { Button, ButtonChildren, PrependIcon, AppendIcon };

import { createSlice } from '@reduxjs/toolkit';

import { dataExtensions, status, statusExtensions } from 'utils/const';
import { selectors } from './selectors';
import { thunks } from './thunks';

const initialState = {
  dataExtensions,
  deliveryExtensions: dataExtensions,
  flipDishMerchant: [],

  getExtensionsStatus: status.IDLE,
  connectedStatus: status.IDLE,
  flipDishStatus: status.IDLE,
  statusDelete: status.IDLE,
};

const slice = createSlice({
  name: 'extensions',
  initialState,
  reducers: {
    DISCONNECT_SERVICE: (state, { payload }) => {
      state.dataExtensions = state.dataExtensions.map((service) => {
        if (service.service === payload) {
          return {
            ...service,
            connect: false,
          };
        }

        return service;
      });
    },

    CLEAR_STATUS: (state) => {
      state.flipDishStatus = status.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.connectExtensionsDelivery.pending, (state) => {
        state.connectedStatus = status.PENDING;
      })
      .addCase(
        thunks.connectExtensionsDelivery.fulfilled,
        (state, { payload }) => {
          state.deliveryExtensions = state.deliveryExtensions.map((el) => {
            if (el.service === payload.provider) {
              return {
                ...el,
                connect: true,
                idDeleteService: payload.id,
              };
            }

            return el;
          });
          state.connectedStatus = status.SUCCESS;
        }
      )
      .addCase(thunks.connectExtensionsDelivery.rejected, (state) => {
        state.connectedStatus = status.FAIL;
      })
      .addCase(thunks.deleteExtensionsDelivery.pending, (state) => {
        state.statusDelete = status.PENDING;
      })
      .addCase(
        thunks.deleteExtensionsDelivery.fulfilled,
        (state, { payload }) => {
          const findIndex = state.deliveryExtensions.findIndex(
            (el) => +el.idDeleteService === +payload
          );

          if (findIndex >= 0) {
            state.deliveryExtensions[findIndex].connect = false;
            state.deliveryExtensions[findIndex].idDeleteService = '';
          }

          state.statusDelete = status.SUCCESS;
        }
      )
      .addCase(thunks.deleteExtensionsDelivery.rejected, (state) => {
        state.statusDelete = status.FAIL;
      })
      .addCase(thunks.deleteExtensions.pending, (state) => {
        state.statusDelete = status.PENDING;
      })
      .addCase(thunks.deleteExtensions.fulfilled, (state) => {
        state.statusDelete = status.SUCCESS;
      })
      .addCase(thunks.deleteExtensions.rejected, (state) => {
        state.statusDelete = status.FAIL;
      })
      .addCase(thunks.getExtensionsDelivery.pending, (state) => {
        state.getExtensionsStatus = status.PENDING;
      })
      .addCase(thunks.getExtensionsDelivery.fulfilled, (state, { payload }) => {
        if (payload.integrations?.length) {
          state.deliveryExtensions = state.deliveryExtensions.map((el) => {
            const integration = payload.integrations.find(
              (item) => item.provider === el.service
            );
            if (integration) {
              return {
                ...el,
                waiting: integration.status === statusExtensions.waiting,
                connect: integration.status === statusExtensions.connect,
                idDeleteService: integration.id,
              };
            }

            return el;
          });
        }

        state.getExtensionsStatus = status.SUCCESS;
      })
      .addCase(thunks.getExtensionsDelivery.rejected, (state) => {
        state.getExtensionsStatus = status.FAIL;
      })
      .addCase(thunks.getExtensions.pending, (state) => {
        state.getExtensionsStatus = status.PENDING;
      })
      .addCase(thunks.connectExtensions.pending, (state) => {
        state.connectedStatus = status.PENDING;
      })
      .addCase(thunks.connectExtensions.fulfilled, (state, { payload }) => {
        state.dataExtensions = state.dataExtensions.map((service) => {
          if (service.service === payload.provider) {
            return {
              ...service,
              connect: true,
              idDeleteService: payload.id,
            };
          }

          return service;
        });

        state.connectedStatus = status.SUCCESS;
      })
      .addCase(thunks.connectExtensions.rejected, (state) => {
        state.connectedStatus = status.FAIL;
      })
      .addCase(thunks.getExtensions.fulfilled, (state, { payload }) => {
        if (payload.length) {
          state.dataExtensions = state.dataExtensions.map((service) => {
            const findProvider = payload.find(
              (provider) => provider.provider === service.service
            );
            if (findProvider) {
              return {
                ...service,
                waiting: findProvider.status === statusExtensions.waiting,
                connect: findProvider.status === statusExtensions.connect,
                idDeleteService: findProvider.id,
              };
            }

            return service;
          });
        }
        state.getExtensionsStatus = status.SUCCESS;
      })
      .addCase(thunks.getMerchantsFlipDish.pending, (state) => {
        state.flipDishStatus = status.PENDING;
      })
      .addCase(thunks.getMerchantsFlipDish.fulfilled, (state, { payload }) => {
        state.flipDishMerchant = payload.map(({ id, name }) => {
          return {
            value: id,
            label: name,
          };
        });
        state.flipDishStatus = status.SUCCESS;
      })
      .addCase(thunks.getMerchantsFlipDish.rejected, (state) => {
        state.flipDishStatus = status.FAIL;
      });
  },
});

const extensions = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { extensions };

export default slice.reducer;

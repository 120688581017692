import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';

export const clients = {
  getClients(params) {
    return http.get(WEB_API_ROUTES.user.clients.getClients, {
      params,
    });
  },
  getClientsDelivery(params) {
    return http.get(WEB_API_ROUTES.user.clientsDelivery.getClientsDelivery, {
      params,
    });
  },
};

import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  dataPromo: [],
  activeTab: 'noPromo',
  promoStatus: status.IDLE,
  promoSaveStatus: status.IDLE,
};

const slice = createSlice({
  name: 'promo',
  initialState,
  reducers: {
    SET_TAB_ACTIVE: (state, { payload }) => {
      state.activeTab = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getPromo.pending, (state) => {
        state.promoStatus = status.PENDING;
      })
      .addCase(thunks.getPromo.fulfilled, (state, { payload }) => {
        const findElem = payload.data.find((el) => el.isActive);

        if (findElem) {
          state.activeTab = findElem.type;
        }

        state.dataPromo = payload.data;
        state.promoStatus = status.SUCCESS;
      })
      .addCase(thunks.getPromo.rejected, (state) => {
        state.promoStatus = status.FAIL;
      })
      .addCase(thunks.savePromo.pending, (state) => {
        state.promoSaveStatus = status.PENDING;
      })
      .addCase(thunks.savePromo.fulfilled, (state, { payload }) => {
        state.dataPromo.push(payload.data);
        state.promoSaveStatus = status.SUCCESS;
      })
      .addCase(thunks.savePromo.rejected, (state) => {
        state.promoSaveStatus = status.FAIL;
      })
      .addCase(thunks.updatePromo.pending, (state) => {
        state.promoSaveStatus = status.PENDING;
      })
      .addCase(thunks.updatePromo.fulfilled, (state, { payload }) => {
        const filterData = state.dataPromo.filter(
          (el) => el.id !== payload.data.id
        );
        state.dataPromo = [...filterData, payload.data];
        state.promoSaveStatus = status.SUCCESS;
      })
      .addCase(thunks.updatePromo.rejected, (state) => {
        state.promoSaveStatus = status.FAIL;
      })
      .addCase(thunks.deactivatePromo.pending, (state) => {
        state.promoSaveStatus = status.PENDING;
      })
      .addCase(thunks.deactivatePromo.fulfilled, (state) => {
        state.promoSaveStatus = status.SUCCESS;
      })
      .addCase(thunks.deactivatePromo.rejected, (state) => {
        state.promoSaveStatus = status.FAIL;
      });
  },
});

const promo = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { promo };
export default slice.reducer;

import { WEB_API_ROUTES } from 'api/api-routes';
import http from 'api/http';

export const settings = {
  getSettings() {
    return http.get(WEB_API_ROUTES.settings.getSettings);
  },

  addSettings(data) {
    return http.post(WEB_API_ROUTES.settings.addSettings, data);
  },

  updateSettings(formData) {
    const { id, data } = formData;
    return http.put(
      WEB_API_ROUTES.settings.updateSettings.replace('{id}', id),
      data
    );
  },

  disableSettings() {
    return http.post(WEB_API_ROUTES.settings.disableSettings);
  },
};

import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';

export const billing = {
  getPlansBilling() {
    return http.get(WEB_API_ROUTES.billing.getPlans);
  },

  addPaymentsMethod(data) {
    return http.post(WEB_API_ROUTES.billing.addPaymentsMethod, data);
  },

  getPaymentsMethod() {
    return http.get(WEB_API_ROUTES.billing.getPaymentsMethod);
  },

  billingSubscription(data) {
    return http.post(WEB_API_ROUTES.billing.billingSubscription, data);
  },

  unSubscription() {
    return http.post(WEB_API_ROUTES.billing.unSubscription);
  },

  getTransactions(params) {
    return http.get(WEB_API_ROUTES.billing.getTransactions, { params });
  },

  getDebts(params) {
    return http.get(WEB_API_ROUTES.billing.getDebts, { params });
  },

  getDebtInfo(id) {
    return http.get(WEB_API_ROUTES.billing.getDebtInfo.replace('{debt}', id));
  },

  payDebt(id) {
    return http.post(WEB_API_ROUTES.billing.payDebt.replace('{debt}', id));
  },
};

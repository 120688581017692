import { createSlice } from '@reduxjs/toolkit';
import { selectors } from './selectors';

const initialState = {
  isSidebarOpened: false,
};

const slice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    TOGGLE_SIDEBAR: (state) => {
      state.isSidebarOpened = !state.isSidebarOpened;
    },
  },
  extraReducers: () => {},
});

const general = {
  actions: slice.actions,
  selectors,
};

export { general };
export default slice.reducer;

const selectors = {
  list: (state) => state.orders.list,
  pagination: (state) => state.orders.pagination,
  filters: (state) => state.orders.filters,
  allOrdersDots: (state) => state.orders.orderMap.allOrdersDots,
  allCourierDots: (state) => state.orders.orderMap.allCourierDots,
  ordersLoadingStatus: (state) => state.orders.ordersLoadingStatus,
  statusAllDotsCourier: (state) => state.orders.ordersLoadingStatus,
  statusAllDotsOrders: (state) => state.orders.statusAllDotsOrders,
  statusOrdersFilter: (state) => state.orders.statusOrdersFilter,
};

export { selectors };

const selectors = {
  getExtensionsStatus: (state) => state.extensions.getExtensionsStatus,
  connectedStatus: (state) => state.extensions.connectedStatus,
  statusDelete: (state) => state.extensions.statusDelete,
  dataExtensions: (state) => state.extensions.dataExtensions,
  deliveryExtensions: (state) => state.extensions.deliveryExtensions,
  flipDishStatus: (state) => state.extensions.flipDishStatus,
  flipDishMerchant: (state) => state.extensions.flipDishMerchant,
};

export { selectors };

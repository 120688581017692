import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getPromo = createAsyncThunk('promo/getPromo', async () => {
  try {
    const { data } = await api.promo.getPromo();
    return data;
  } catch (err) {
    console.log(err);
    throw err;
  }
});

const savePromo = createAsyncThunk('promo/savePromo', async (formData) => {
  try {
    const { data } = await api.promo.savePromo(formData);
    toast.success('Success');
    return data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const updatePromo = createAsyncThunk('promo/updatePromo', async (formData) => {
  try {
    const { data } = await api.promo.updatePromo(formData);
    toast.success('Success');
    return data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const deactivatePromo = createAsyncThunk('promo/deactivatePromo', async () => {
  try {
    const { data } = await api.promo.deactivatePromo();
    toast.success('Success');
    return data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const thunks = {
  getPromo,
  savePromo,
  updatePromo,
  deactivatePromo,
};

export { thunks };

import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { selectors } from './selectors';
import { thunks } from './thunks';

const initialState = {
  menu: undefined,
  category: undefined,
  dish: undefined,

  statusMenu: status.IDLE,
  createMenuStatus: status.IDLE,
  statusCreateCategory: status.IDLE,
  getCategoryStatus: status.IDLE,
  deleteCategory: status.IDLE,
  putCategoryStatus: status.IDLE,
  createDishStatus: status.IDLE,
  deleteDishStatus: status.IDLE,
  getDishStatus: status.IDLE,
  menuExportStatus: status.IDLE,
  dishExportStatus: status.IDLE,
  updateDishStatus: status.IDLE,
};

const slice = createSlice({
  name: 'restaurantMenu',
  initialState,
  reducers: {
    CLEAR_STATUS: (state) => {
      state.statusCreateCategory = status.IDLE;
      state.deleteCategory = status.IDLE;
      state.putCategoryStatus = status.IDLE;
      state.createDishStatus = status.IDLE;
      state.updateDishStatus = status.IDLE;
    },
    CLEAR_MENU: (state) => {
      state.menu = undefined;
    },
    CLEAR_CATEGORY: (state) => {
      state.category = undefined;
    },
    CLEAR_DISH: (state) => {
      state.dish = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getRestaurantMenu.pending, (state) => {
        state.statusMenu = status.PENDING;
      })
      .addCase(thunks.getRestaurantMenu.fulfilled, (state, { payload }) => {
        if (payload.length) {
          // eslint-disable-next-line prefer-destructuring
          state.menu = payload[0];
        }

        state.statusMenu = status.SUCCESS;
      })
      .addCase(thunks.getRestaurantMenu.rejected, (state) => {
        state.statusMenu = status.FAIL;
      })

      .addCase(thunks.createRestaurantMenu.pending, (state) => {
        state.createMenuStatus = status.PENDING;
      })
      .addCase(thunks.createRestaurantMenu.fulfilled, (state, { payload }) => {
        state.menu = payload;
        state.createMenuStatus = status.SUCCESS;
      })
      .addCase(thunks.createRestaurantMenu.rejected, (state) => {
        state.createMenuStatus = status.FAIL;
      })

      .addCase(thunks.createCategory.pending, (state) => {
        state.statusCreateCategory = status.PENDING;
      })
      .addCase(thunks.createCategory.fulfilled, (state) => {
        state.statusCreateCategory = status.SUCCESS;
      })
      .addCase(thunks.createCategory.rejected, (state) => {
        state.statusCreateCategory = status.FAIL;
      })

      .addCase(thunks.deleteCategory.pending, (state) => {
        state.deleteCategory = status.PENDING;
      })
      .addCase(thunks.deleteCategory.fulfilled, (state, { payload }) => {
        state.menu.categories = state.menu.categories.filter(
          (target) => target.id !== payload
        );
        state.deleteCategory = status.SUCCESS;
      })
      .addCase(thunks.deleteCategory.rejected, (state) => {
        state.deleteCategory = status.FAIL;
      })

      .addCase(thunks.putCategoryMenu.pending, (state) => {
        state.putCategoryStatus = status.PENDING;
      })
      .addCase(thunks.putCategoryMenu.fulfilled, (state) => {
        state.putCategoryStatus = status.SUCCESS;
      })
      .addCase(thunks.putCategoryMenu.rejected, (state) => {
        state.putCategoryStatus = status.FAIL;
      })

      .addCase(thunks.getCategoryMenu.pending, (state) => {
        state.getCategoryStatus = status.PENDING;
      })
      .addCase(thunks.getCategoryMenu.fulfilled, (state, { payload }) => {
        state.category = payload;
        state.getCategoryStatus = status.SUCCESS;
      })
      .addCase(thunks.getCategoryMenu.rejected, (state) => {
        state.getCategoryStatus = status.FAIL;
      })

      .addCase(thunks.createDish.pending, (state) => {
        state.createDishStatus = status.PENDING;
      })
      .addCase(thunks.createDish.fulfilled, (state) => {
        state.createDishStatus = status.SUCCESS;
      })
      .addCase(thunks.createDish.rejected, (state) => {
        state.createDishStatus = status.FAIL;
      })

      .addCase(thunks.deleteDish.pending, (state) => {
        state.deleteDishStatus = status.PENDING;
      })
      .addCase(thunks.deleteDish.fulfilled, (state, { payload }) => {
        state.category.dishes = state.category.dishes.filter(
          (target) => target.id !== payload
        );
        state.deleteDishStatus = status.SUCCESS;
      })
      .addCase(thunks.deleteDish.rejected, (state) => {
        state.deleteDishStatus = status.FAIL;
      })

      .addCase(thunks.getDish.pending, (state) => {
        state.getDishStatus = status.PENDING;
      })
      .addCase(thunks.getDish.fulfilled, (state, { payload }) => {
        state.dish = payload;
        state.getDishStatus = status.SUCCESS;
      })
      .addCase(thunks.getDish.rejected, (state) => {
        state.getDishStatus = status.FAIL;
      })

      .addCase(thunks.updateDish.pending, (state) => {
        state.updateDishStatus = status.PENDING;
      })
      .addCase(thunks.updateDish.fulfilled, (state) => {
        state.updateDishStatus = status.SUCCESS;
      })
      .addCase(thunks.updateDish.rejected, (state) => {
        state.updateDishStatus = status.FAIL;
      })

      .addCase(thunks.menuExport.pending, (state) => {
        state.menuExportStatus = status.PENDING;
      })
      .addCase(thunks.menuExport.fulfilled, (state) => {
        state.menuExportStatus = status.SUCCESS;
      })
      .addCase(thunks.menuExport.rejected, (state) => {
        state.menuExportStatus = status.FAIL;
      })

      .addCase(thunks.dishExport.pending, (state) => {
        state.dishExportStatus = status.PENDING;
      })
      .addCase(thunks.dishExport.fulfilled, (state) => {
        state.dishExportStatus = status.SUCCESS;
      })
      .addCase(thunks.dishExport.rejected, (state) => {
        state.dishExportStatus = status.FAIL;
      });
  },
});

const restaurantMenu = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { restaurantMenu };

export default slice.reducer;

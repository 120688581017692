import { user } from 'api/modules/user/user';
import { order } from 'api/modules/order/order';
import { courier } from 'api/modules/courier/courier';
import { clients } from 'api/modules/clients/clients';
import { auth } from './modules/auth/auth';
import { merchant } from './modules/merchant/merchant';
import { extensions } from './modules/extensions/extensions';
import { registration } from './modules/registration/registration';
import { settings } from './modules/settings/settings';
import { promo } from './modules/promo/promo';
import { courierTracking } from './modules/courierTracking/courierTracking';
import { pricing } from './modules/pricing/pricing';
import { billing } from './modules/billing/billing';
import { restaurantMenu } from './modules/restaurantMenu/restaurantMenu';

export const api = {
  auth,
  registration,
  user,
  order,
  courier,
  clients,
  merchant,
  extensions,
  settings,
  promo,
  courierTracking,
  pricing,
  billing,
  restaurantMenu,
};

import styled from '@emotion/styled';
import { css } from '@emotion/react';

const LogoWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 240px;
  height: 50px;
  margin: 0 auto;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  ${({ fullSize }) =>
    !fullSize &&
    css`
      justify-content: center;
    `}
  height: 55px;
  width: 100%;
`;

export { LogoWrap, LogoContainer };

import { createSlice } from '@reduxjs/toolkit';

import { orderStatuses, status } from 'utils/const';
import { formatTime, pagination } from 'utils/prepare';
import { thunks } from './thunks';
import { selectors } from './selectors';

const filterOrdersResponse = (state, payload) => {
  const { current_page, from, to, total, per_page } = payload.meta;
  const { data } = payload;
  state.list = data.map((el) => {
    const createdTime = formatTime(el.createdAt);
    const pickupTimes = formatTime(el.pickupTime);
    const assign =
      el.status === orderStatuses.newOrder ||
      el.status === orderStatuses.delayedPickup;
    return {
      ...el,
      merchantName: el?.merchant?.title,
      assignStatus: assign,
      createdTime,
      pickupTimes,
    };
  });
  state.pagination = pagination(current_page, from, to, total, per_page);
  state.ordersLoadingStatus = status.SUCCESS;
};

const initialState = {
  list: [],
  pagination: {
    current: 1,
    perPage: 7,
    total: 1,
  },
  filters: {
    status: null,
  },
  orderMap: {
    allOrdersDots: [],
    allCourierDots: [],
  },
  ordersLoadingStatus: status.PENDING,
  statusAllDotsCourier: status.IDLE,
  statusAllDotsOrders: status.IDLE,
  statusOrdersFilter: status.IDLE,
};

const slice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    SET_FILTERS: (state, { payload }) => {
      state.filters = { ...state.filters, ...payload };
    },
    RESET_FILTER: (state) => {
      state.filters = initialState.filters;
    },
    CHANGE_CURRENT_PAGE: (state, { payload }) => {
      state.pagination.current = payload;
    },
    CLEAR_STATUS: (state) => {
      state.ordersLoadingStatus = status.PENDING;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getOrders.pending, (state) => {
        state.ordersLoadingStatus = status.PENDING;
      })
      .addCase(thunks.getOrders.fulfilled, (state, { payload }) => {
        filterOrdersResponse(state, payload);
        state.ordersLoadingStatus = status.SUCCESS;
      })
      .addCase(thunks.getOrders.rejected, (state) => {
        state.ordersLoadingStatus = status.FAIL;
      })
      .addCase(thunks.getOrdersFilter.rejected, (state) => {
        state.statusOrdersFilter = status.FAIL;
      })
      .addCase(thunks.getOrdersFilter.pending, (state) => {
        state.statusOrdersFilter = status.PENDING;
      })
      .addCase(thunks.getOrdersFilter.fulfilled, (state, { payload }) => {
        filterOrdersResponse(state, payload);
        state.statusOrdersFilter = status.SUCCESS;
      })
      .addCase(thunks.getAllDotsOrders.pending, (state) => {
        state.statusAllDotsOrders = status.PENDING;
      })
      .addCase(thunks.getAllDotsOrders.fulfilled, (state, { payload }) => {
        state.statusAllDotsOrders = status.SUCCESS;
        state.orderMap.allOrdersDots = payload.data;
      })
      .addCase(thunks.getAllDotsOrders.rejected, (state) => {
        state.statusAllDotsOrders = status.FAIL;
      })
      .addCase(thunks.getAllDotsCourier.pending, (state) => {
        state.statusAllDotsCourier = status.PENDING;
      })
      .addCase(thunks.getAllDotsCourier.fulfilled, (state, { payload }) => {
        state.orderMap.allCourierDots = payload.data;
        state.statusAllDotsCourier = status.SUCCESS;
      })
      .addCase(thunks.getAllDotsCourier.rejected, (state) => {
        state.statusAllDotsCourier = status.FAIL;
      })
      .addCase(thunks.getAllDotsCourierDelivery.pending, (state) => {
        state.statusAllDotsCourier = status.PENDING;
      })
      .addCase(
        thunks.getAllDotsCourierDelivery.fulfilled,
        (state, { payload }) => {
          state.orderMap.allCourierDots = payload.data;
          state.statusAllDotsCourier = status.SUCCESS;
        }
      )
      .addCase(thunks.getAllDotsCourierDelivery.rejected, (state) => {
        state.statusAllDotsCourier = status.FAIL;
      });
  },
});

const orders = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { orders };
export default slice.reducer;

import { WEB_API_ROUTES } from 'api/api-routes';
import http from 'api/http';

export const promo = {
  getPromo() {
    return http.get(WEB_API_ROUTES.settings.getPromo);
  },

  savePromo(data) {
    return http.post(WEB_API_ROUTES.settings.getPromo, data);
  },

  deactivatePromo() {
    return http.post(WEB_API_ROUTES.settings.deactivatePromo);
  },

  updatePromo(formData) {
    const { id, data } = formData;
    return http.post(
      WEB_API_ROUTES.settings.updatePromo.replace('{id}', id),
      data
    );
  },
};

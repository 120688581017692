const selectors = {
  courierInfo: (state) => state.courier.courierInfo,
  pagination: (state) => state.courier.pagination,
  orders: (state) => state.courier.orders,
  editCourierStatus: (state) => state.courier.editCourierStatus,
  getCourierStatus: (state) => state.courier.getCourierStatus,
  deleteCourierStatus: (state) => state.courier.deleteCourierStatus,
  createCourierStatus: (state) => state.courier.createCourierStatus,
};

export { selectors };

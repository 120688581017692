const selectors = {
  token: (state) => state.auth.token,
  webPushToken: (state) => state.auth.webPushToken,
  role: (state) => state.auth.role,
  user: (state) => state.auth.user,
  loginStatus: (state) => state.auth.loginStatus,
  registerStatus: (state) => state.auth.registerStatus,
  logoutStatus: (state) => state.auth.logoutStatus,
  userGeolocation: (state) => state.auth.userGeolocation,
  checkCodeStatus: (state) => state.auth.checkCodeStatus,
  changePasswordStatus: (state) => state.auth.changePasswordStatus,
  getCodeStatus: (state) => state.auth.getCodeStatus,
  deleteProfileStatus: (state) => state.auth.deleteProfileStatus,
};

export { selectors };

const selectors = {
  data: (state) => state.route.data,
  list: (state) => state.route.list,
  notAssignedOrdersList: (state) => state.route.notAssignedOrdersList,
  contentLoadingStatus: (state) => state.route.contentLoadingStatus,
  notAssignedOrdersListLoadingStatus: (state) =>
    state.route.notAssignedOrdersListLoadingStatus,
};

export { selectors };

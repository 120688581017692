import { css } from '@emotion/react';

const Block = ({
  w = 'auto',
  h = 'auto',
  p = 0,
  m = 0,
  b = 'none',
  bg = 'transparent',
}) => css`
  width: ${w};
  height: ${h};
  padding: ${p};
  margin: ${m};
  border: ${b};
  background: ${bg};
`;

const Flex = ({ align = 'center', justify = 'flex-start' }) => css`
  display: flex;
  align-items: ${align};
  justify-content: ${justify};
`;

const TextEllipsis = ({ width }) => css`
  width: ${width};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TextEllipsisLines = ({ linesCount = 3, width = '200px' }) => css`
  display: -webkit-box;
  max-width: ${width};
  -webkit-line-clamp: ${linesCount};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const ScrollBar = () => css`
  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #e2e2e2;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 5px;
  }
`;

const ScrollBarHidden = () => css`
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`;

export {
  Block,
  Flex,
  TextEllipsis,
  TextEllipsisLines,
  ScrollBar,
  ScrollBarHidden,
};

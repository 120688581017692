const selectors = {
  menu: (state) => state.restaurantMenu.menu,
  category: (state) => state.restaurantMenu.category,
  dish: (state) => state.restaurantMenu.dish,
  statusMenu: (state) => state.restaurantMenu.statusMenu,
  createMenuStatus: (state) => state.restaurantMenu.createMenuStatus,
  statusCreateCategory: (state) => state.restaurantMenu.statusCreateCategory,
  getCategoryStatus: (state) => state.restaurantMenu.getCategoryStatus,
  putCategoryStatus: (state) => state.restaurantMenu.putCategoryStatus,
  createDishStatus: (state) => state.restaurantMenu.createDishStatus,
  deleteDishStatus: (state) => state.restaurantMenu.deleteDishStatus,
  getDishStatus: (state) => state.restaurantMenu.getDishStatus,
  menuExportStatus: (state) => state.restaurantMenu.menuExportStatus,
  dishExportStatus: (state) => state.restaurantMenu.dishExportStatus,
  updateDishStatus: (state) => state.restaurantMenu.updateDishStatus,
  deleteCategory: (state) => state.restaurantMenu.deleteCategory,
};

export { selectors };

import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';

export const extensions = {
  getExtensions() {
    return http.get(WEB_API_ROUTES.user.extensions.integration);
  },

  getExtensionsDelivery() {
    return http.get(WEB_API_ROUTES.user.extensions.integrationDelivery);
  },

  deleteExtensions(id) {
    return http.delete(
      WEB_API_ROUTES.user.extensions.integrationUpdate.replace(
        '{integration}',
        id
      )
    );
  },

  deleteExtensionsDelivery(id) {
    return http.delete(
      WEB_API_ROUTES.user.extensions.integrationUpdateDelivery.replace(
        '{integration}',
        id
      )
    );
  },

  connectExtensions(data) {
    return http.post(WEB_API_ROUTES.user.extensions.integration, data);
  },

  connectExtensionsDelivery(data) {
    return http.post(WEB_API_ROUTES.user.extensions.integrationDelivery, data);
  },

  getMerchantsFlipDish(data) {
    return http.post(WEB_API_ROUTES.user.extensions.getMerchantsFlipDish, data);
  },
};

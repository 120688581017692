const selectors = {
  data: (state) => state.order.data,
  createOrderStatus: (state) => state.order.createOrderStatus,
  orderLoadingStatus: (state) => state.order.orderLoadingStatus,
  allMerchants: (state) => state.order.allMerchants,
  forwardStatus: (state) => state.order.forwardStatus,
  stuartMerchantStatus: (state) => state.order.stuartMerchantStatus,
  assignStatus: (state) => state.order.assignStatus,
  assignData: (state) => state.order.assignData,
};

export { selectors };

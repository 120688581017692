import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getCouriers = createAsyncThunk(
  'couriers/getCouriers',
  async ({ filters, pageIndex }) => {
    try {
      const { data } = await api.courier.getCouriers({
        ...filters,
        page: pageIndex,
      });
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getCouriersDelivery = createAsyncThunk(
  'couriers/getCouriersDelivery',
  async ({ filters, pageIndex }) => {
    try {
      const { data } = await api.courier.getCouriersDelivery({
        ...filters,
        page: pageIndex,
      });
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getAllCouriers = createAsyncThunk(
  'couriers/getAllCouriers',
  async (params) => {
    try {
      const { data } = await api.courier.getAllCouriers(params);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getAllCouriersDelivery = createAsyncThunk(
  'couriers/getAllCouriersDelivery',
  async (params) => {
    try {
      const { data } = await api.courier.getAllCouriersDelivery(params);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getAllCouriers,
  getAllCouriersDelivery,
  getCouriersDelivery,
  getCouriers,
};

export { thunks };

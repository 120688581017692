import PropTypes from 'prop-types';

import { RLoader } from 'components/UI/RLoader';
import { AppendIcon, Button, ButtonChildren, PrependIcon } from './styled';

RButton.propTypes = {
  type: PropTypes.string,
  variant: PropTypes.string,
  prependIcon: PropTypes.element,
  appendIcon: PropTypes.element,
  fullWidth: PropTypes.bool,
  height: PropTypes.number,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  css: PropTypes.object,
  form: PropTypes.string,
  loading: PropTypes.bool,
};

export function RButton({
  type = 'button',
  variant = 'primary',
  prependIcon,
  appendIcon,
  fullWidth,
  height = 40,
  disabled,
  onClick = () => {},
  loading,
  children,
  css,
  form,
}) {
  return (
    <Button
      tabindex="-1"
      type={type}
      variant={variant}
      fullWidth={fullWidth}
      height={height}
      disabled={disabled}
      onClick={onClick}
      css={css}
      form={form}
    >
      <ButtonChildren>
        {loading ? (
          <RLoader />
        ) : (
          <>
            {prependIcon && <PrependIcon>{prependIcon}</PrependIcon>}
            {children}
            {appendIcon && <AppendIcon>{appendIcon}</AppendIcon>}
          </>
        )}
      </ButtonChildren>
    </Button>
  );
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getCourierAssignedOrders = createAsyncThunk(
  'route/getRouteContent',
  async ({ courierId }) => {
    try {
      const { data } = await api.courier.getCourierAssignedOrders({
        courierId,
      });
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getNotAssignedOrdersList = createAsyncThunk(
  'route/getNotAssignedOrdersList',
  async () => {
    try {
      const { data } = await api.user.getNotAssignedOrders();
      return data.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getNotAssignedOrdersListDelivery = createAsyncThunk(
  'route/getNotAssignedOrdersListDeliv',
  async () => {
    try {
      const { data } = await api.user.getNotAssignedOrdersListDelivery();
      return data.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const sendOrderToCourier = createAsyncThunk(
  'route/sendOrderToCourier',
  async ({ orderIds }) => {
    try {
      const res = await api.courier.sendOrderToCourier({ orderIds });
      toast.success(res.data?.message);
      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const cancelAssignments = createAsyncThunk(
  'route/cancelAssignments',
  async ({ orderIds }) => {
    try {
      const res = await api.courier.cancelAssignments({ orderIds });
      toast.success(res.data?.message);
      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getNotAssignedOrdersListDelivery,
  getCourierAssignedOrders,
  getNotAssignedOrdersList,
  sendOrderToCourier,
  cancelAssignments,
};

export { thunks };

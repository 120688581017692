import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const createCourier = createAsyncThunk(
  'courier/createCourier',
  async ({ formData }) => {
    try {
      const { data } = await api.courier.createCourier(formData);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const createCourierDelivery = createAsyncThunk(
  'courier/createCourierDelivery',
  async ({ formData }) => {
    try {
      const { data } = await api.courier.createCourierDelivery(formData);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const editCourier = createAsyncThunk(
  'courier/editCourier',
  async ({ formData, id }) => {
    try {
      const { data } = await api.courier.editCourier({ params: formData, id });
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const deleteCourier = createAsyncThunk(
  'courier/deleteCourier',
  async ({ id }) => {
    try {
      const { data } = await api.courier.deleteCourier(id);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getCourierById = createAsyncThunk(
  'courier/getCourierById',
  async ({ id, pageIndex }) => {
    try {
      const { data } = await api.courier.getCourierById({
        params: { page: pageIndex },
        id,
      });
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getCourierByIdAllOrders = createAsyncThunk(
  'courier/getCourierByIdAllOrders',
  async ({ id, pageIndex }) => {
    try {
      const { data } = await api.courier.getCourierByIdAllOrders({
        params: { page: pageIndex },
        id,
      });
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  createCourier,
  createCourierDelivery,
  deleteCourier,
  editCourier,
  getCourierById,
  getCourierByIdAllOrders,
};

export { thunks };

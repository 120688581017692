import { WEB_API_ROUTES } from 'api/api-routes';
import http from 'api/http';

export const merchant = {
  getMerchant(params) {
    return http.get(WEB_API_ROUTES.merchantDelivery.getMerchant, { params });
  },
  createMerchant(formData) {
    return http.post(WEB_API_ROUTES.merchantDelivery.createMerchant, formData);
  },
  updateMerchant({ id, formData }) {
    return http.put(
      WEB_API_ROUTES.merchantDelivery.updateMerchant.replace('{merchant}', id),
      formData
    );
  },
  updateMerchantPsw(data) {
    return http.post(WEB_API_ROUTES.merchantDelivery.updateMerchantPsw, data);
  },
  getMerchantId(id) {
    return http.get(
      WEB_API_ROUTES.merchantDelivery.getMerchantId.replace('{id}', id)
    );
  },
  deleteExtensionsMerchant(id) {
    return http.delete(
      WEB_API_ROUTES.user.extensions.integrationUpdateDelivery.replace(
        '{integration}',
        id
      )
    );
  },
  connectExtensionsMerchant(data) {
    return http.post(WEB_API_ROUTES.user.extensions.integrationDelivery, data);
  },
  deleteMerchant(id) {
    return http.delete(
      WEB_API_ROUTES.merchantDelivery.deleteMerchant.replace('{merchant}', id)
    );
  },
};

const selectors = {
  pagination: (state) => state.taskPricing.pagination,
  flagDelete: (state) => state.taskPricing.flagDelete,
  data: (state) => state.taskPricing.data,
  getPricingStatus: (state) => state.taskPricing.getPricingStatus,
  targetDeleteId: (state) => state.taskPricing.targetDeleteId,
  statusDelete: (state) => state.taskPricing.statusDelete,
  addRulesPricingStatus: (state) => state.taskPricing.addRulesPricingStatus,
  defaultStatusRules: (state) => state.taskPricing.defaultStatusRules,
  getRulesIdStatus: (state) => state.taskPricing.getRulesIdStatus,
  editRules: (state) => state.taskPricing.editRules,
  activeRules: (state) => state.taskPricing.activeRules,
};

export { selectors };

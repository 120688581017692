import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getClients = createAsyncThunk(
  'clients/getClients',
  async ({ pageIndex }) => {
    try {
      const { data } = await api.clients.getClients({
        page: pageIndex,
      });
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getClientsDelivery = createAsyncThunk(
  'clients/getClientsDelivery',
  async ({ pageIndex }) => {
    try {
      const { data } = await api.clients.getClientsDelivery({
        page: pageIndex,
      });
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getClients,
  getClientsDelivery,
};

export { thunks };

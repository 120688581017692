import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getRestaurantMenu = createAsyncThunk(
  'restaurantMenu/getMenu',
  async () => {
    try {
      const { data } = await api.restaurantMenu.getMenu();
      return data.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const createRestaurantMenu = createAsyncThunk(
  'restaurantMenu/createMenu',
  async () => {
    try {
      const { data } = await api.restaurantMenu.createMenu();
      return data.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const createCategory = createAsyncThunk(
  'restaurantMenu/createCategory',
  async (formData) => {
    try {
      const { data } = await api.restaurantMenu.createCategory(formData);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const deleteCategory = createAsyncThunk(
  'restaurantMenu/deleteCategory',
  async (id) => {
    try {
      await api.restaurantMenu.deleteCategory(id);
      return id;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getCategoryMenu = createAsyncThunk(
  'restaurantMenu/getCategoryMenu',
  async (id) => {
    try {
      const { data } = await api.restaurantMenu.getCategoryMenu(id);
      return data.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const putCategoryMenu = createAsyncThunk(
  'restaurantMenu/putCategoryMenu',
  async (formData) => {
    try {
      const { data } = await api.restaurantMenu.putCategoryMenu(formData);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const createDish = createAsyncThunk(
  'restaurantMenu/createDish',
  async (formData) => {
    try {
      const { data } = await api.restaurantMenu.createDish(formData);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const deleteDish = createAsyncThunk('restaurantMenu/deleteDish', async (id) => {
  try {
    await api.restaurantMenu.deleteDish(id);
    return id;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const getDish = createAsyncThunk('restaurantMenu/getDish', async (id) => {
  try {
    const { data } = await api.restaurantMenu.getDish(id);
    return data.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const updateDish = createAsyncThunk(
  'restaurantMenu/updateDish',
  async (formData) => {
    try {
      const { data } = await api.restaurantMenu.updateDish(formData);
      return data.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const menuExport = createAsyncThunk('restaurantMenu/menuExport', async () => {
  try {
    await api.restaurantMenu.menuExport();
    toast.success('Success');
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const dishExport = createAsyncThunk('restaurantMenu/dishExport', async (id) => {
  try {
    await api.restaurantMenu.dishExport(id);
    toast.success('Success');
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const thunks = {
  getRestaurantMenu,
  createRestaurantMenu,
  getCategoryMenu,
  createCategory,
  deleteCategory,
  putCategoryMenu,
  createDish,
  deleteDish,
  getDish,
  updateDish,
  menuExport,
  dishExport,
};

export { thunks };

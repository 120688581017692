import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getSettings = createAsyncThunk('settings/getSettings', async () => {
  try {
    const { data } = await api.settings.getSettings();
    return data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const addSettings = createAsyncThunk(
  'settings/addSettings',
  async (formData) => {
    try {
      const { data } = await api.settings.addSettings(formData);
      toast.success('Success');
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const updateSettings = createAsyncThunk(
  'settings/updateSettings',
  async (formData) => {
    try {
      const { data } = await api.settings.updateSettings(formData);
      toast.success('Success');
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const disableSettings = createAsyncThunk(
  'settings/disableSettings',
  async () => {
    try {
      const { data } = await api.settings.disableSettings();
      toast.success('Success');
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getSettings,
  addSettings,
  updateSettings,
  disableSettings,
};

export { thunks };

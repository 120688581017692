import { WEB_API_ROUTES } from 'api/api-routes';
import http from 'api/http';

export const pricing = {
  getPricingRules(params) {
    return http.get(WEB_API_ROUTES.settings.pricing, { params });
  },
  addRulesPricing(data) {
    return http.post(WEB_API_ROUTES.settings.pricing, data);
  },
  updateRulesPricing(formData) {
    const { data, id } = formData;
    return http.put(
      WEB_API_ROUTES.settings.getRulesId.replace('{pricing}', id),
      data
    );
  },
  deleteRulesPricing(id) {
    return http.delete(
      WEB_API_ROUTES.settings.deleteRulesPricing.replace('{pricing}', id)
    );
  },
  getRulesId(id) {
    return http.get(
      WEB_API_ROUTES.settings.getRulesId.replace('{pricing}', id)
    );
  },
  setDefaultRulesPricing(id) {
    return http.post(
      WEB_API_ROUTES.settings.setDefaultRulesPricing.replace('{pricing}', id)
    );
  },
};

import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { pagination } from 'utils/prepare';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  courierInfo: {},
  orders: [],
  pagination: {},
  getCourierStatus: status.IDLE,
  createCourierStatus: status.IDLE,
  deleteCourierStatus: status.IDLE,
  editCourierStatus: status.IDLE,
};

const courierInfo = (state, payload) => {
  const { courier } = payload;
  const {
    meta: { current_page, from, to, total, per_page },
    data,
  } = payload.orders;

  state.getCourierStatus = status.SUCCESS;
  state.courierInfo = courier;
  state.orders = data;
  state.pagination = pagination(current_page, from, to, total, per_page);
};

const slice = createSlice({
  name: 'courier',
  initialState,
  reducers: {
    WRITE_COURIER_INFO: (state, { payload }) => {
      state.courierInfo = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.createCourier.pending, (state) => {
        state.createCourierStatus = status.PENDING;
      })
      .addCase(thunks.createCourier.fulfilled, (state, { payload }) => {
        state.data = payload.data;
        state.createCourierStatus = status.SUCCESS;
      })
      .addCase(thunks.createCourier.rejected, (state) => {
        state.createCourierStatus = status.FAIL;
      })

      .addCase(thunks.editCourier.pending, (state) => {
        state.createCourierStatus = status.PENDING;
      })
      .addCase(thunks.editCourier.fulfilled, (state, { payload }) => {
        state.data = payload.data;
        state.createCourierStatus = status.SUCCESS;
      })
      .addCase(thunks.editCourier.rejected, (state) => {
        state.createCourierStatus = status.FAIL;
      })

      .addCase(thunks.deleteCourier.pending, (state) => {
        state.createCourierStatus = status.PENDING;
      })
      .addCase(thunks.deleteCourier.fulfilled, (state, { payload }) => {
        state.data = payload.data;
        state.createCourierStatus = status.SUCCESS;
      })
      .addCase(thunks.deleteCourier.rejected, (state) => {
        state.createCourierStatus = status.FAIL;
      })
      .addCase(thunks.getCourierById.pending, (state) => {
        state.getCourierStatus = status.PENDING;
      })
      .addCase(thunks.getCourierById.fulfilled, (state, { payload }) => {
        courierInfo(state, payload);
      })
      .addCase(thunks.getCourierById.rejected, (state) => {
        state.getCourierStatus = status.FAIL;
      })
      .addCase(thunks.getCourierByIdAllOrders.pending, (state) => {
        state.getCourierStatus = status.PENDING;
      })
      .addCase(
        thunks.getCourierByIdAllOrders.fulfilled,
        (state, { payload }) => {
          courierInfo(state, payload);
        }
      )
      .addCase(thunks.getCourierByIdAllOrders.rejected, (state) => {
        state.getCourierStatus = status.FAIL;
      });
  },
});

const courier = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { courier };
export default slice.reducer;

import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getAnalyticStatistic = createAsyncThunk(
  'clients/getAnalyticStatistic',
  async () => {
    try {
      const { data } = await api.user.getAnalyticStatistic();
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getStatisticDelivery = createAsyncThunk(
  'clients/getStatisticDelivery',
  async () => {
    try {
      const { data } = await api.user.getStatisticDelivery();
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getAnalyticHeatMap = createAsyncThunk(
  'clients/getAnalyticHeatMap',
  async (param) => {
    try {
      const params = {
        days: param.days || 1,
        merchantId: param.merchantId,
      };
      const { data } = await api.user.getAnalyticHeatMap(params);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getAnalyticStatistic,
  getStatisticDelivery,
  getAnalyticHeatMap,
};

export { thunks };

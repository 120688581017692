import { WEB_API_ROUTES } from 'api/api-routes';
import http from 'api/http';

export const registration = {
  register(formData) {
    return http.post(WEB_API_ROUTES.registration.register, formData);
  },
  getBusiness() {
    return http.get(WEB_API_ROUTES.registration.getBusiness);
  },
  verifyAccount(formData) {
    return http.post(WEB_API_ROUTES.registration.verifyAccount, formData);
  },
};

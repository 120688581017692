import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { pagination } from 'utils/prepare';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  data: [],
  editRules: undefined,

  activeRules: '',

  targetDeleteId: '',
  flagDelete: false,

  pagination: {
    current: 1,
    perPage: 7,
    total: 1,
  },

  getPricingStatus: status.PENDING,
  defaultStatusRules: status.IDLE,
  statusDelete: status.PENDING,
  addRulesPricingStatus: status.IDLE,
  getRulesIdStatus: status.IDLE,
};

const slice = createSlice({
  name: 'taskPricing',
  initialState,
  reducers: {
    SET_FLAG_DELETE: (state, { payload }) => {
      state.flagDelete = payload;
    },
    SET_DELETE_ID: (state, { payload }) => {
      state.targetDeleteId = payload;
    },
    CLEAR_STATUS: (state) => {
      state.addRulesPricingStatus = status.IDLE;
      state.statusDelete = status.PENDING;
    },
    SET_STATUS_PRICING: (state) => {
      state.getPricingStatus = status.PENDING;
    },
    SET_ACTIVE_RULES: (state, { payload }) => {
      state.activeRules = payload;
    },
    CLEAR_TARGET_EDIT: (state) => {
      state.editRules = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(thunks.getPricingRules.pending, (state) => {
      state.getPricingStatus = status.PENDING;
    });
    builder.addCase(thunks.getPricingRules.fulfilled, (state, { payload }) => {
      const { current_page, from, to, total, per_page } = payload.meta;
      const activeDef = payload.data.find((item) => item.isDefault);

      if (activeDef) {
        state.activeRules = activeDef.id;
      }

      state.data = payload.data;
      state.pagination = pagination(current_page, from, to, total, per_page);
      state.getPricingStatus = status.SUCCESS;
    });
    builder
      .addCase(thunks.getPricingRules.rejected, (state) => {
        state.getPricingStatus = status.FAIL;
      })
      .addCase(thunks.deleteRulesPricing.pending, (state) => {
        state.statusDelete = status.PENDING;
      })
      .addCase(thunks.deleteRulesPricing.fulfilled, (state) => {
        state.flagDelete = false;
        state.targetDeleteId = '';
        state.statusDelete = status.SUCCESS;
      })
      .addCase(thunks.deleteRulesPricing.rejected, (state) => {
        state.statusDelete = status.FAIL;
      })
      .addCase(thunks.setDefaultRulesPricing.pending, (state) => {
        state.defaultStatusRules = status.PENDING;
      })
      .addCase(thunks.setDefaultRulesPricing.fulfilled, (state) => {
        state.defaultStatusRules = status.SUCCESS;
      })
      .addCase(thunks.setDefaultRulesPricing.rejected, (state) => {
        state.defaultStatusRules = status.FAIL;
      })
      .addCase(thunks.addRulesPricing.pending, (state) => {
        state.addRulesPricingStatus = status.PENDING;
      })
      .addCase(thunks.addRulesPricing.fulfilled, (state) => {
        state.addRulesPricingStatus = status.SUCCESS;
      })
      .addCase(thunks.addRulesPricing.rejected, (state) => {
        state.addRulesPricingStatus = status.FAIL;
      })
      .addCase(thunks.updateRulesPricing.pending, (state) => {
        state.addRulesPricingStatus = status.PENDING;
      })
      .addCase(thunks.updateRulesPricing.fulfilled, (state) => {
        state.addRulesPricingStatus = status.SUCCESS;
      })
      .addCase(thunks.updateRulesPricing.rejected, (state) => {
        state.addRulesPricingStatus = status.FAIL;
      })
      .addCase(thunks.getRulesId.pending, (state) => {
        state.getRulesIdStatus = status.PENDING;
      })
      .addCase(thunks.getRulesId.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.editRules = {
          ...data,
          merchants: data.merchants.map(({ id, title }) => ({
            value: id,
            label: title,
          })),
        };
        state.getRulesIdStatus = status.SUCCESS;
      })
      .addCase(thunks.getRulesId.rejected, (state) => {
        state.getRulesIdStatus = status.FAIL;
      });
  },
});

const taskPricing = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { taskPricing };
export default slice.reducer;

import { createSlice } from '@reduxjs/toolkit';

import { iconVehicleCourier, status } from 'utils/const';
import Merchant from 'assets/img/map/retaraunt.svg';
import Customer from 'assets/img/map/order-delivery.svg';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  courier: {},
  dataMap: [],
  statusDelivered: false,
  statusNotDelivered: false,
  promo: null,
  statusOrderInfo: status.IDLE,
};

const slice = createSlice({
  name: 'courierTracking',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getCourierInfo.fulfilled, (state, { payload }) => {
        const {
          courier,
          customer,
          merchant,
          status: statusDelivery,
          promo,
        } = payload ?? {};

        state.promo = promo;
        state.statusDelivered = statusDelivery === 'delivered';
        state.statusNotDelivered = statusDelivery === 'notDelivered';
        const courierIcon = courier.deliveryType
          ? iconVehicleCourier[courier.deliveryType]
          : iconVehicleCourier.pedestrian;

        state.courier = courier;
        state.dataMap = [
          { ...merchant, icon: Merchant },
          { ...customer, icon: Customer },
          { ...courier, icon: courierIcon },
        ];
        state.statusOrderInfo = status.SUCCESS;
      })
      .addCase(thunks.getCourierInfo.rejected, (state) => {
        state.statusOrderInfo = status.FAIL;
      });
  },
});

const courierTracking = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { courierTracking };
export default slice.reducer;

import styled from '@emotion/styled';

import { Block, TextEllipsis } from '../mixins';

const Typography = styled.div`
  ${Block};
  ${TextEllipsis};
  width: ${({ w }) => w};
  font-size: ${({ fz = '14px' }) => fz};
  font-weight: ${({ fw = 400 }) => fw};
  font-style: ${({ fs = 'normal' }) => fs};
  text-transform: ${({ tt = 'none' }) => tt};
  text-decoration: ${({ td = 'none' }) => td};
  text-align: ${({ ta = 'left' }) => ta};
  color: ${({ color = 'initial' }) => color};
  ${({ css }) => css};
`;

export { Typography };

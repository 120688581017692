import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getDebts = createAsyncThunk('debts/getDebts', async (params) => {
  try {
    const { data } = await api.billing.getDebts(params);
    toast.success(data.message);
    return data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const getDebtInfo = createAsyncThunk('debts/getDebtInfo', async (debtId) => {
  try {
    const { data } = await api.billing.getDebtInfo(debtId);
    toast.success(data.message);
    return data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const payDebt = createAsyncThunk('debts/payDebt', async (debtId) => {
  try {
    const { data } = await api.billing.payDebt(debtId);
    toast.success(data.message);
    return data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const thunks = {
  getDebts,
  getDebtInfo,
  payDebt,
};

export { thunks };

import PropTypes from 'prop-types';

import { ButtonIcon } from './styled';

RButtonIcon.propTypes = {
  size: PropTypes.number,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export function RButtonIcon({
  size = 24,
  onClick = () => {},
  disabled,
  children,
}) {
  return (
    <ButtonIcon
      size={size}
      disabled={disabled}
      onClick={!disabled ? onClick : null}
    >
      {children}
    </ButtonIcon>
  );
}

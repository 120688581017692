import PropTypes from 'prop-types';

import { Wrapper } from './styled';

RLoader.propTypes = {
  size: PropTypes.string,
  border: PropTypes.string,
  color: PropTypes.string,
};

export function RLoader({ size = 21, border = 2, color }) {
  return (
    <Wrapper
      color={color}
      size={size}
      border={border}
    />
  );
}

import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';
import { toast } from 'react-toastify';

const getMerchant = createAsyncThunk(
  'delivery/getMerchant',
  async (pageIndex) => {
    try {
      const { data } = await api.merchant.getMerchant({
        page: pageIndex,
      });
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const createMerchant = createAsyncThunk(
  'delivery/createMerchant',
  async (formData) => {
    try {
      const { data } = await api.merchant.createMerchant(formData);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const deleteMerchant = createAsyncThunk(
  'delivery/deleteMerchant',
  async (id) => {
    try {
      const { data } = await api.merchant.deleteMerchant(id);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const updateMerchant = createAsyncThunk(
  'delivery/updateMerchant',
  async (params) => {
    try {
      const { data } = await api.merchant.updateMerchant(params);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);
const updateMerchantPsw = createAsyncThunk(
  'delivery/updateMerchantPsw',
  async (params) => {
    try {
      const { data } = await api.merchant.updateMerchantPsw(params);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getMerchantId = createAsyncThunk('delivery/getMerchantId', async (id) => {
  try {
    const { data } = await api.merchant.getMerchantId(id);
    return data?.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const deleteExtensionsMerchant = createAsyncThunk(
  'deleteExtensionsMerchant',
  async (id) => {
    try {
      const { data } = await api.merchant.deleteExtensionsMerchant(id);
      if (data.status) {
        toast.success(data.message);
      }
      return id;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const connectExtensionsMerchant = createAsyncThunk(
  'connectExtensionsMerchant',
  async (formData) => {
    try {
      const { data } = await api.merchant.connectExtensionsMerchant(formData);
      return data?.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getMerchant,
  createMerchant,
  deleteMerchant,
  updateMerchant,
  getMerchantId,
  updateMerchantPsw,
  deleteExtensionsMerchant,
  connectExtensionsMerchant,
};

export { thunks };

import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getOrders = createAsyncThunk(
  'orders/getOrders',
  async ({ filters, pageIndex }) => {
    try {
      const { data } = await api.user.getOrders({
        ...filters,
        page: pageIndex,
      });
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getOrdersDelivery = createAsyncThunk(
  'orders/getOrders',
  async ({ filters, pageIndex }) => {
    try {
      const { data } = await api.user.getOrdersDelivery({
        ...filters,
        page: pageIndex,
      });
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getOrdersFilter = createAsyncThunk(
  'orders/getOrdersFilter',
  async ({ filters, pageIndex }) => {
    try {
      const { data } = await api.user.getOrdersFilter({
        ...filters,
        page: pageIndex,
      });
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getAllDotsOrders = createAsyncThunk(
  'orders/getAllDotsOrders',
  async () => {
    try {
      const { data } = await api.user.getAllDotsOrders();
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getAllDotsCourier = createAsyncThunk(
  'orders/getAllDotsCourier',
  async () => {
    try {
      const { data } = await api.user.getAllDotsCourier();
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getAllDotsCourierDelivery = createAsyncThunk(
  'orders/getAllDotsCourierDelivery',
  async () => {
    try {
      const { data } = await api.user.getAllDotsCourierDelivery();
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getOrders,
  getOrdersDelivery,
  getOrdersFilter,
  getAllDotsOrders,
  getAllDotsCourier,
  getAllDotsCourierDelivery,
};

export { thunks };

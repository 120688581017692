import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { thunks } from './thunks';
import { selectors } from './selectors';

const setAuthData = (state, payload) => {
  const { token, user } = payload;
  state.token = token;
  state.role = user.role;
  state.user = user;
};

const initialState = {
  token: null,
  role: null,
  webPushToken: null,
  userGeolocation: null,
  user: {},
  loginStatus: status.IDLE,
  registerStatus: status.IDLE,
  logoutStatus: status.IDLE,
  checkCodeStatus: status.IDLE,
  changePasswordStatus: status.IDLE,
  getCodeStatus: status.IDLE,
  deleteProfileStatus: status.IDLE,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    SET_LOGIN: (state, { payload }) => {
      setAuthData(state, payload);
    },
    SET_STATUS_APP_SUCCESS: (state) => {
      state.loginStatus = status.SUCCESS;
    },
    RESET_STATE: (state) => {
      Object.assign(state, initialState);
    },
    WRITE_WEB_PUSH_ID: (state, { payload }) => {
      state.webPushToken = payload;
    },
    SET_INFO_USER: (state, { payload }) => {
      state.user = payload;
    },
    SET_GEO_USER: (state, { payload }) => {
      state.userGeolocation = payload;
    },
    CLEAR_STATUS: (state) => {
      state.checkCodeStatus = status.IDLE;
      state.changePasswordStatus = status.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.authLogin.pending, (state) => {
        state.loginStatus = status.PENDING;
      })
      .addCase(thunks.authLogin.fulfilled, (state) => {
        state.loginStatus = status.SUCCESS;
      })
      .addCase(thunks.authLogin.rejected, (state) => {
        state.loginStatus = status.FAIL;
      })

      .addCase(thunks.authLogout.pending, (state) => {
        state.logoutStatus = status.PENDING;
      })
      .addCase(thunks.authLogout.fulfilled, (state) => {
        Object.assign(state, initialState);
        state.logoutStatus = status.SUCCESS;
      })
      .addCase(thunks.authLogout.rejected, (state) => {
        state.logoutStatus = status.FAIL;
      })

      .addCase(thunks.checkCode.pending, (state) => {
        state.checkCodeStatus = status.PENDING;
      })
      .addCase(thunks.checkCode.fulfilled, (state) => {
        state.checkCodeStatus = status.SUCCESS;
      })
      .addCase(thunks.checkCode.rejected, (state) => {
        state.checkCodeStatus = status.FAIL;
      })

      .addCase(thunks.changePassword.pending, (state) => {
        state.changePasswordStatus = status.PENDING;
      })
      .addCase(thunks.changePassword.fulfilled, (state) => {
        state.changePasswordStatus = status.SUCCESS;
      })
      .addCase(thunks.changePassword.rejected, (state) => {
        state.changePasswordStatus = status.FAIL;
      })

      .addCase(thunks.getCode.pending, (state) => {
        state.getCodeStatus = status.PENDING;
      })
      .addCase(thunks.getCode.fulfilled, (state) => {
        state.getCodeStatus = status.SUCCESS;
      })
      .addCase(thunks.getCode.rejected, (state) => {
        state.getCodeStatus = status.FAIL;
      })

      .addCase(thunks.deleteProfile.pending, (state) => {
        state.deleteProfileStatus = status.PENDING;
      })
      .addCase(thunks.deleteProfile.fulfilled, (state) => {
        state.deleteProfileStatus = status.SUCCESS;
      })
      .addCase(thunks.deleteProfile.rejected, (state) => {
        state.deleteProfileStatus = status.FAIL;
      });
  },
});

const auth = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { auth };
export default slice.reducer;

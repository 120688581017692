import {
  IconMark,
  IconMethodCreated,
  IconMethodFlipDish,
  IconMethodGloriaFood,
  IconMethodGlovo,
  IconMethodJustEat,
  IconMethodUber,
  IconOnlineDot,
  IconStatusArrowRight,
  IconStatusChevronRight,
  IconStatusChevronUp,
  IconStatusClock,
  IconStatusCross,
  IconStatusDoubleChevronRight,
  IconStatusDoubleTick,
  LastAppIcon,
  RushourIcon,
  ShopifyIcon,
} from 'components/Icons';
import IconDelivery from 'assets/img/map/icon-delivery.svg';
import IconOrderDelivery from 'assets/img/map/order-delivery.svg';
import IconBusyCourierDelivery from 'assets/img/map/busy-courier-delivery.svg';
import IconFreeCourierDelivery from 'assets/img/map/free-courier-delivery.svg';
import SelectedOrderMarker from 'assets/img/map/icon-selected-order.svg';
import IconMerchant from 'assets/img/map/google-map-home.svg';
import IconOrder from 'assets/img/map/icon-order.svg';
import IconBusyCourier from 'assets/img/map/icon-courier-busy.svg';
import IconFreeCourier from 'assets/img/map/icon-courier-free.svg';
import CourierCar from 'assets/img/map/courierCar.svg';
import CourierBicycle from 'assets/img/map/courierBicycle.svg';
import CourierScooter from 'assets/img/map/courierScooter.svg';
import { appRoles } from './roles';

export const iconsMap = {
  [appRoles.DELIVERY]: {
    home: IconDelivery,
    order: IconOrderDelivery,
    selectOrder: IconOrderDelivery,
    busyCourier: IconBusyCourierDelivery,
    freeCourier: IconFreeCourierDelivery,
  },
  [appRoles.USER]: {
    home: IconMerchant,
    order: IconOrder,
    selectOrder: SelectedOrderMarker,
    busyCourier: IconBusyCourier,
    freeCourier: IconFreeCourier,
  },
};

const status = {
  IDLE: 'idle',
  PENDING: 'pending',
  SUCCESS: 'success',
  FAIL: 'fail',
};

const plansType = {
  basic: 1,
  advanced: 2,
  full: 3,
};

const orderStatuses = {
  newOrder: 'newOrder',
  delayedPickup: 'delayedPickup',
  orderAssigned: 'orderAssigned',
  orderSent: 'orderSent',
  onTheWay: 'onTheWay',
  delivered: 'delivered',
  notDelivered: 'notDelivered',
};

const mapOptions = [
  { value: '1', label: '24 hours' },
  { value: '7', label: '7 days' },
  { value: '30', label: '1 month' },
  { value: '90', label: '3 months' },
];

const courierOptions = [
  { value: 'pedestrian', label: 'Pedestrian' },
  { value: 'bicycle', label: 'Bicycle' },
  { value: 'bike', label: 'Bike' },
  { value: 'car', label: 'Car' },
];

const iconVehicleCourier = {
  pedestrian: IconFreeCourierDelivery,
  bicycle: CourierBicycle,
  bike: CourierScooter,
  car: CourierCar,
};

const orderStatusAnalytics = [
  {
    text: 'New',
    total: 0,
    value: 'newOrder',
  },
  {
    text: 'Delayed',
    total: 0,
    value: 'delayedPickup',
  },
  {
    text: 'Assigned',
    total: 0,
    value: 'orderAssigned',
  },
  {
    text: 'Sent',
    total: 0,
    value: 'orderSent',
  },
  {
    text: 'On the way',
    total: 0,
    value: 'onTheWay',
  },
  {
    text: 'Delivered',
    total: 0,
    value: 'delivered',
  },
  {
    text: 'Not delivered',
    total: 0,
    value: 'notDelivered',
  },
];

const agentStatus = [
  {
    text: 'Busy',
    total: 0,
    value: 'Busy',
  },
  {
    text: 'Free',
    total: 0,
    value: 'Free',
  },
  {
    text: 'Inactive',
    total: 0,
    value: 'Inactive',
  },
];

const orderStatusTabs = [
  {
    id: 1,
    label: 'All orders',
    value: 'allOrders',
    icon: null,
  },
  {
    id: 2,
    label: 'New order',
    value: orderStatuses.newOrder,
    icon: <IconStatusChevronUp />,
  },
  {
    id: 3,
    label: 'Delayed',
    value: orderStatuses.delayedPickup,
    icon: <IconStatusClock />,
  },
  {
    id: 4,
    label: 'Assigned',
    value: orderStatuses.orderAssigned,
    icon: <IconStatusChevronRight />,
  },
  {
    id: 5,
    label: 'Sent',
    value: orderStatuses.orderSent,
    icon: <IconStatusDoubleChevronRight />,
  },
  {
    id: 6,
    label: 'On the way',
    value: orderStatuses.onTheWay,
    icon: <IconStatusArrowRight />,
  },
  {
    id: 7,
    label: 'Delivered',
    value: orderStatuses.delivered,
    icon: <IconStatusDoubleTick />,
  },
  {
    id: 8,
    label: 'Not delivered',
    value: orderStatuses.notDelivered,
    icon: <IconStatusCross />,
  },
];

const courierStatusTabs = [
  {
    id: 1,
    label: 'All couriers',
    value: 'allCouriers',
    icon: null,
  },
  {
    id: 2,
    label: 'Online',
    value: 'online',
    icon: <IconOnlineDot />,
  },
  {
    id: 3,
    label: 'Offline',
    value: 'offline',
    icon: <IconOnlineDot offline />,
  },
  {
    id: 4,
    label: 'Free',
    value: 'free',
    icon: <IconStatusChevronUp />,
  },
  {
    id: 5,
    label: 'Busy',
    value: 'busy',
    icon: <IconMark />,
  },
];

const receiveMethodIcons = {
  internal: IconMethodCreated,
  glovo: IconMethodGlovo,
  uberEats: IconMethodUber,
  justEat: IconMethodJustEat,
  flipdish: IconMethodFlipDish,
  gloriafood: IconMethodGloriaFood,
  rusHour: RushourIcon,
  shopify: ShopifyIcon,
  lastApp: LastAppIcon,
};

const receiveMethodNames = {
  internal: 'Created',
  glovo: 'Glovo',
  uberEats: 'Uber Eats',
  justEat: 'Just Eat',
  flipdish: 'Flip Dish',
  gloriafood: 'Gloria Food',
  rusHour: 'Rushour',
  shopify: 'Shopify',
  lastApp: 'Last.App',
};

const receiveExtenshion = {
  internal: 'Created',
  glovo: 'Glovo',
  uberEats: 'UberEats',
  justEat: 'Just Eat',
  flipdish: 'Flip Dish',
  gloriafood: 'Gloriafood',
  rusHour: 'Rushour',
  shopify: 'Shopify',
  lastApp: 'Last.App',
};

const serviceData = {
  stuart: 'Stuart',
  uberDelivery: 'Uber Delivery',
};

const statusExtensions = {
  waiting: 'waiting',
  connect: 'connected',
};

const dataExtensions = [
  {
    service: 'glovo',
    connect: false,
    waiting: false,
    idDeleteService: '',
    developing: true,
  },
  {
    service: 'uberEats',
    connect: false,
    waiting: false,
    iconInfo: true,
    webHook: `${process.env.REACT_APP_BASE_URL}/api/webhook/uber-eats/dispatched`,
    idDeleteService: '',
  },
  {
    service: 'justEat',
    connect: false,
    waiting: false,
    idDeleteService: '',
    developing: true,
  },
  {
    service: 'gloriafood',
    connect: false,
    waiting: false,
    iconInfo: true,
    webHook: `${process.env.REACT_APP_BASE_URL}/api/webhook/gloria-food/dispatched`,
    idDeleteService: '',
  },
  {
    service: 'flipdish',
    connect: false,
    waiting: false,
    idDeleteService: '',
  },
  {
    service: 'rusHour',
    connect: false,
    waiting: false,
    idDeleteService: '',
  },
  {
    service: 'shopify',
    connect: false,
    waiting: false,
    idDeleteService: '',
    developing: true,
  },
  {
    service: 'lastApp',
    connect: false,
    waiting: false,
    idDeleteService: '',
    webHook: `${process.env.REACT_APP_BASE_URL}/api/webhook/gloria-food/dispatched`,
  },
];

const initFirebaseConfig = {
  apiKey: process.env.REACT_APP_WEB_PUSH_API_KEY,
  authDomain: process.env.REACT_APP_WEB_PUSH_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_WEB_PUSH_PROJECT_ID,
  storageBucket: process.env.REACT_APP_WEB_PUSH_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_WEB_PUSH_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_WEB_PUSH_APP_ID,
  measurementId: process.env.REACT_APP_WEB_PUSH_MEASUREMENT_ID,
};

const dataTabsPromo = [
  { value: 'noPromo', label: 'No Promo' },
  { value: 'my', label: 'Your Promo' },
  { value: 'youtube', label: 'YouTube Promo' },
  { value: 'spotify', label: 'Spotify Promo' },
];

const stripeOptions = {
  style: {
    base: {
      fontSize: 16,
      color: '#303030',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#C9C9C9',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

const thirdParty = {
  typeStuart: 'stuart',
  typeUber: 'uber',
  typeCatcher: 'catcher',
};

export {
  status,
  orderStatusTabs,
  courierStatusTabs,
  receiveMethodNames,
  receiveMethodIcons,
  orderStatuses,
  initFirebaseConfig,
  courierOptions,
  orderStatusAnalytics,
  agentStatus,
  mapOptions,
  dataExtensions,
  receiveExtenshion,
  dataTabsPromo,
  iconVehicleCourier,
  stripeOptions,
  plansType,
  serviceData,
  statusExtensions,
  thirdParty,
};

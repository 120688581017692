import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getPlansBilling = createAsyncThunk(
  'billing/getPlansBilling',
  async () => {
    try {
      const { data } = await api.billing.getPlansBilling();
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const addPaymentsMethod = createAsyncThunk(
  'billing/addPaymentsMethod',
  async (formData) => {
    try {
      const { data } = await api.billing.addPaymentsMethod(formData);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getPaymentsMethod = createAsyncThunk(
  'billing/getPaymentsMethod',
  async () => {
    try {
      const { data } = await api.billing.getPaymentsMethod();
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const billingSubscription = createAsyncThunk(
  'billing/billingSubscription',
  async (formData) => {
    try {
      const { data } = await api.billing.billingSubscription(formData);
      if (data.message !== '3dsRequired') {
        toast.success(data.message);
      }
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const unSubscription = createAsyncThunk(
  'billing/billingUnSubscription',
  async () => {
    try {
      const { data } = await api.billing.unSubscription();
      toast.success(data.message);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getTransactions = createAsyncThunk(
  'billing/getTransactions',
  async (params) => {
    try {
      const { data } = await api.billing.getTransactions(params);
      toast.success(data.message);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getPlansBilling,
  addPaymentsMethod,
  getPaymentsMethod,
  billingSubscription,
  unSubscription,
  getTransactions,
};

export { thunks };

import { WEB_API_ROUTES } from 'api/api-routes';
import http from '../../http';

export const order = {
  getOrderDetails(id) {
    return http.get(
      WEB_API_ROUTES.user.order.getOrderDetails.replace('{order}', id)
    );
  },
  getOrderDetailsDelivery(id) {
    return http.get(
      WEB_API_ROUTES.user.deliveryOrder.getOrderDetails.replace('{order}', id)
    );
  },
  assignOrder(params) {
    return http.post(WEB_API_ROUTES.user.order.assignOrder, params);
  },
  createOrder(data) {
    return http.post(WEB_API_ROUTES.user.order.createOrder, data);
  },
  createOrderDelivery(data) {
    return http.post(
      WEB_API_ROUTES.user.orderDelivery.createOrderDelivery,
      data
    );
  },
  deleteOrder(id) {
    return http.delete(
      WEB_API_ROUTES.user.order.deleteOrder.replace('{order}', id)
    );
  },
  cancelOrder(id, status) {
    return http.put(
      WEB_API_ROUTES.user.order.cancelOrder.replace('{order}', id),
      status
    );
  },
  getAllMerchant() {
    return http.get(WEB_API_ROUTES.user.order.getAllMerchant);
  },
  stuartForward(id) {
    return http.post(
      WEB_API_ROUTES.user.order.stuartForward.replace('{order}', id)
    );
  },
  uberEatsForward(id) {
    return http.post(
      WEB_API_ROUTES.user.order.uberEatsForward.replace('{order}', id)
    );
  },
  catcherForward(id) {
    return http.post(
      WEB_API_ROUTES.user.order.catcherForward.replace('{order}', id)
    );
  },
  getAssignMethod(id) {
    return http.get(
      WEB_API_ROUTES.user.order.assingMethods.replace('{order}', id)
    );
  },
};

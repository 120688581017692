import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getNotification = createAsyncThunk(
  'notification/getNotification',
  async (attribute) => {
    try {
      const { page, date } = attribute ?? {};
      const params = {
        page: page || 1,
        date: date || '',
      };
      const { data } = await api.user.getNotification(params);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const refresh = createAsyncThunk('notification/refresh', async () => {
  try {
    const { data } = await api.user.getNotification({ page: 1 });
    return data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const getNotificationCount = createAsyncThunk(
  'notification/getNotificationCount',
  async () => {
    try {
      const { data } = await api.user.getNotificationCount();
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const readNotification = createAsyncThunk(
  'notification/readNotification',
  async (value) => {
    try {
      const params = {
        notificationIds: value,
      };
      await api.user.readNotification(params);
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const allReadNotification = createAsyncThunk(
  'notification/allReadNotification',
  async () => {
    try {
      await api.user.readNotification();
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const saveUserIdWebPush = createAsyncThunk(
  'notification/saveUserIdWebPush',
  async (token) => {
    try {
      const { data } = await api.user.saveUserIdWebPush({ token });
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const deleteUserIdWebPush = createAsyncThunk(
  'notification/deleteUserIdWebPush',
  async (token) => {
    try {
      const { data } = await api.user.deleteUserIdWebPush({ token });
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getNotification,
  getNotificationCount,
  readNotification,
  allReadNotification,
  refresh,
  saveUserIdWebPush,
  deleteUserIdWebPush,
};

export { thunks };

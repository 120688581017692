import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { selectors } from './selectors';
import { thunks } from './thunks';

const initialState = {
  pagination: {
    currentPage: 1,
    lastPage: 2,
  },
  params: {
    search: false,
    date: false,
  },

  notification: [],
  stateOldIdNotif: [],
  notificationIdsChecked: [],
  newNotificationCount: false,
  statusNotification: status.PENDING,
};

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    SEARCH_DATE_ACTIVE: (state, { payload }) => {
      state.params.search = payload;
    },
    WRITE_DATE: (state, { payload }) => {
      state.params.date = payload;
    },
    WRITE_OLD_NOTIF: (state, { payload }) => {
      state.stateOldIdNotif = payload;
    },
    CLEAR_LIST_NOTIFICATION: (state) => {
      state.notification = [];
      state.statusNotification = status.PENDING;
    },
    NEW_NOTIFICATION: (state) => {
      state.newNotificationCount += 1;
    },
    ADD_ID_CHECKED: (state, { payload }) => {
      state.notificationIdsChecked.push(payload);
      const indexReadElement = state.notification.findIndex(
        (el) => el.id === payload
      );

      if (indexReadElement >= 0) {
        state.notification[indexReadElement].isRead = 1;
      }

      state.newNotificationCount = state.newNotificationCount
        ? state.newNotificationCount - 1
        : 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getNotification.fulfilled, (state, { payload }) => {
        const { data, meta } = payload;
        if (!state.params.date) {
          state.pagination.currentPage += 1;
          state.pagination.lastPage = meta.last_page;

          state.notification = state.notification.length
            ? [...state.notification, ...data]
            : data;
        } else {
          state.pagination.currentPage = state.params.search
            ? 2
            : state.pagination.currentPage + 1;
          state.notification = state.params.search
            ? data
            : [...state.notification, ...data];
          state.params.search = false;
        }
      })
      .addCase(thunks.allReadNotification.fulfilled, (state) => {
        state.notification = state.notification.map((el) => {
          return { ...el, isRead: 1 };
        });
        state.newNotificationCount = 0;
      })
      .addCase(thunks.refresh.pending, (state) => {
        state.statusNotification = status.PENDING;
      })
      .addCase(thunks.refresh.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.pagination.currentPage = 2;
        state.params.date = false;
        state.notification = data;
        state.statusNotification = status.SUCCESS;
      })
      .addCase(thunks.refresh.rejected, (state) => {
        state.statusNotification = status.FAIL;
      })
      .addCase(thunks.getNotificationCount.fulfilled, (state, { payload }) => {
        const { newNotificationCount } = payload;
        state.newNotificationCount = newNotificationCount;
      });
  },
});

const notification = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { notification };

export default slice.reducer;

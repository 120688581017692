import { createSlice } from '@reduxjs/toolkit';

import { dataExtensions, status } from 'utils/const';
import { pagination } from 'utils/prepare';
import { selectors } from './selectors';
import { thunks } from './thunks';

const initialState = {
  list: [],
  pagination: {},
  address: null,
  merchantExtensions: dataExtensions,
  saveValueForm: null,
  merchant: {},
  statusGerMerchant: status.IDLE,
  deliveryLoadingStatus: status.PENDING,
  connectedStatus: status.IDLE,
  statusDelete: status.IDLE,
};

const slice = createSlice({
  name: 'merchantDelivery',
  initialState,
  reducers: {
    WRITE_ADDRESS: (state, { payload }) => {
      state.address = payload;
    },
    CLEAR_STATUS: (state) => {
      state.deliveryLoadingStatus = status.PENDING;
    },
    SAVE_VALUE: (state, { payload }) => {
      state.saveValueForm = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.connectExtensionsMerchant.pending, (state) => {
        state.connectedStatus = status.PENDING;
      })
      .addCase(
        thunks.connectExtensionsMerchant.fulfilled,
        (state, { payload }) => {
          state.merchantExtensions = state.merchantExtensions.map((service) => {
            if (service.service === payload.provider) {
              return {
                ...service,
                connect: true,
                idDeleteService: payload.id,
              };
            }

            return service;
          });
          state.connectedStatus = status.SUCCESS;
        }
      )
      .addCase(thunks.connectExtensionsMerchant.rejected, (state) => {
        state.connectedStatus = status.FAIL;
      })
      .addCase(thunks.deleteExtensionsMerchant.pending, (state) => {
        state.statusDelete = status.PENDING;
      })
      .addCase(
        thunks.deleteExtensionsMerchant.fulfilled,
        (state, { payload }) => {
          state.merchantExtensions = state.merchantExtensions.map((el) => {
            if (el.idDeleteService === payload) {
              return {
                ...el,
                connect: false,
                idDeleteService: '',
              };
            }

            return el;
          });
          state.statusDelete = status.SUCCESS;
        }
      )
      .addCase(thunks.deleteExtensionsMerchant.rejected, (state) => {
        state.statusDelete = status.FAIL;
      })
      .addCase(thunks.getMerchant.pending, (state) => {
        state.deliveryLoadingStatus = status.PENDING;
      })
      .addCase(thunks.getMerchant.fulfilled, (state, { payload }) => {
        const {
          meta: { current_page, from, to, total, per_page },
          data,
        } = payload;
        state.list = data;
        state.pagination = pagination(current_page, from, to, total, per_page);
        state.deliveryLoadingStatus = status.SUCCESS;
      })
      .addCase(thunks.getMerchant.rejected, (state) => {
        state.deliveryLoadingStatus = status.FAIL;
      })
      .addCase(thunks.getMerchantId.pending, (state) => {
        state.statusGerMerchant = status.PENDING;
      })
      .addCase(thunks.getMerchantId.fulfilled, (state, { payload }) => {
        const { integrations } = payload;
        if (integrations.length) {
          state.merchantExtensions = state.merchantExtensions.map((el) => {
            const searchService = integrations.find(
              (service) => service.provider === el.service
            );

            if (searchService) {
              return {
                ...el,
                connect: true,
                idDeleteService: searchService.id,
              };
            }

            return el;
          });
        }
        state.merchant = payload;
        state.statusGerMerchant = status.SUCCESS;
      })
      .addCase(thunks.getMerchantId.rejected, (state) => {
        state.statusGerMerchant = status.FAIL;
      });
  },
});

const merchantDelivery = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { merchantDelivery };

export default slice.reducer;

import styled from '@emotion/styled';
import { css } from '@emotion/react';

const Wrap = styled.div`
  position: relative;
  width: 100%;
  max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '300px')};
  ${({ css }) => css}
`;

const FieldWrap = styled.div`
  position: relative;
`;

const Label = styled.label`
  position: absolute;
  top: ${({ focus }) => (focus ? '0' : '50%')};
  left: 12px;
  padding: 0 4px;
  font-size: ${({ focus }) => (focus ? '12px' : '14px')};
  line-height: 1;
  color: ${({ error, theme }) =>
    error ? theme.error : theme.field.labelColor};
  pointer-events: none;
  transform: translateY(-50%);
  z-index: 1;
  transition: 0.1s linear;

  ${({ focus }) =>
    focus &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #fff;
        z-index: -1;
      }
    `};
`;

const LabelStatic = styled.label`
  display: block;
  margin-bottom: 6px;
  margin-left: 10px;
  font-size: ${(props) => props.theme.font.sizeMd};
  line-height: 1;
  color: ${({ error, theme }) =>
    error ? theme.error : theme.field.labelColor};
  transition: 0.1s linear;
  text-align: left;
`;

const RootFieldInput = styled.input`
  display: block;
  width: 100%;
  height: ${({ height }) => `${height}px`};
  font-size: ${({ height }) => (height === 28 ? '12px' : '14px')};
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.field.textColor};
  background-color: ${({ theme }) => theme.field.bgColor};
  border: 1px solid
    ${({ error, theme }) => (error ? theme.error : theme.field.borderColor)};
  border-radius: 3px;

  &::placeholder {
    color: rgba(77, 77, 77, 0.35);
  }

  ${({ labelStatic }) =>
    !labelStatic &&
    css`
      &::placeholder {
        opacity: 0;
        transition: opacity 0.15s linear;
      }

      &:focus {
        &::placeholder {
          opacity: 1;
        }
      }
    `}
`;

const HelperText = styled.div`
  flex: 1 1 auto;
  min-height: 18px;
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.error};
  margin: 2px 0;
`;

const RootFieldIcons = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const FieldDropdown = styled.div`
  position: absolute;
  top: calc(100% - 1px);
  border-radius: 0 0 6px 6px;
  left: 0;
  width: ${({ w = '100%' }) => w};
  height: auto;
  max-height: 175px;
  background-color: #ffffff;
  border: 1px solid ${(props) => props.theme.field.borderColor};
  overflow-y: auto;
  z-index: 100;
`;

const RootFieldDropdownOption = styled.div`
  display: flex;
  align-items: center;
  color: #989898;
  line-height: 1;
  border-bottom: 1px solid #dfdfdf;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  ${({ active, theme }) =>
    active &&
    css`
      background-color: ${theme.table.hover};
    `}

  &:hover {
    background-color: ${({ theme }) => theme.table.hover};
  }
`;

export {
  Wrap,
  Label,
  LabelStatic,
  FieldWrap,
  RootFieldInput,
  HelperText,
  RootFieldIcons,
  FieldDropdown,
  RootFieldDropdownOption,
};

import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { pagination } from 'utils/prepare';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  list: [],
  allCouriersList: [],
  pagination: {
    current: 1,
    perPage: 7,
    total: 1,
  },
  filters: {
    status: null,
  },
  allCouriersLoadingStatus: status.IDLE,
  couriersLoadingStatus: status.PENDING,
};

const slice = createSlice({
  name: 'couriers',
  initialState,
  reducers: {
    SET_FILTERS: (state, { payload }) => {
      state.filters = { ...state.filters, ...payload };
    },
    RESET_FILTER: (state) => {
      state.filters = initialState.filters;
    },
    CLEAR_STATUS: (state) => {
      state.couriersLoadingStatus = status.PENDING;
    },
    SET_STATUS_IDLE: (state) => {
      state.couriersLoadingStatus = status.IDLE;
    },
    RESET_LIST_COURIERS: (state) => {
      state.list = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getCouriers.pending, (state) => {
        state.couriersLoadingStatus = status.PENDING;
      })
      .addCase(
        thunks.getCouriers.fulfilled,
        (
          state,
          {
            payload: {
              // eslint-disable-next-line camelcase
              meta: { current_page, from, to, total, per_page },
              data,
            },
          }
        ) => {
          state.list = data;
          state.pagination = pagination(
            current_page,
            from,
            to,
            total,
            per_page
          );
          state.couriersLoadingStatus = status.SUCCESS;
        }
      )

      .addCase(thunks.getCouriers.rejected, (state) => {
        state.couriersLoadingStatus = status.FAIL;
      })

      .addCase(thunks.getCouriersDelivery.pending, (state) => {
        state.couriersLoadingStatus = status.PENDING;
      })

      .addCase(
        thunks.getCouriersDelivery.fulfilled,
        (
          state,
          {
            payload: {
              // eslint-disable-next-line camelcase
              meta: { current_page, from, to, total, per_page },
              data,
            },
          }
        ) => {
          state.list = data;
          state.pagination = pagination(
            current_page,
            from,
            to,
            total,
            per_page
          );
          state.couriersLoadingStatus = status.SUCCESS;
        }
      )

      .addCase(thunks.getCouriersDelivery.rejected, (state) => {
        state.couriersLoadingStatus = status.FAIL;
      })

      .addCase(thunks.getAllCouriers.pending, (state) => {
        state.allCouriersLoadingStatus = status.PENDING;
      })
      .addCase(
        thunks.getAllCouriers.fulfilled,
        (state, { payload: { data } }) => {
          state.allCouriersList = data;
          state.allCouriersLoadingStatus = status.SUCCESS;
        }
      )
      .addCase(thunks.getAllCouriers.rejected, (state) => {
        state.allCouriersLoadingStatus = status.FAIL;
      })

      .addCase(thunks.getAllCouriersDelivery.pending, (state) => {
        state.allCouriersLoadingStatus = status.PENDING;
      })
      .addCase(
        thunks.getAllCouriersDelivery.fulfilled,
        (state, { payload: { data } }) => {
          state.allCouriersList = data;
          state.allCouriersLoadingStatus = status.SUCCESS;
        }
      )
      .addCase(thunks.getAllCouriersDelivery.rejected, (state) => {
        state.allCouriersLoadingStatus = status.FAIL;
      });
  },
});

const couriers = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { couriers };
export default slice.reducer;

import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { thunks } from 'store/user/courier/route/thunks';
import { selectors } from 'store/user/courier/route/selectors';

const initialState = {
  data: {},
  list: [],
  notAssignedOrdersList: [],
  sendOrderStatus: status.IDLE,
  contentLoadingStatus: status.IDLE,
  notAssignedOrdersListLoadingStatus: status.IDLE,
};

const slice = createSlice({
  name: 'route',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getCourierAssignedOrders.pending, (state) => {
        state.contentLoadingStatus = status.PENDING;
      })
      .addCase(
        thunks.getCourierAssignedOrders.fulfilled,
        (state, { payload }) => {
          state.data = payload.courier;
          state.list = payload.orders;
          state.contentLoadingStatus = status.SUCCESS;
        }
      )
      .addCase(thunks.getCourierAssignedOrders.rejected, (state) => {
        state.contentLoadingStatus = status.FAIL;
      })

      .addCase(thunks.sendOrderToCourier.pending, (state) => {
        state.sendOrderStatus = status.PENDING;
      })
      .addCase(thunks.sendOrderToCourier.fulfilled, (state) => {
        state.sendOrderStatus = status.SUCCESS;
      })
      .addCase(thunks.sendOrderToCourier.rejected, (state) => {
        state.sendOrderStatus = status.FAIL;
      })

      .addCase(thunks.getNotAssignedOrdersList.pending, (state) => {
        state.notAssignedOrdersListLoadingStatus = status.PENDING;
      })
      .addCase(
        thunks.getNotAssignedOrdersList.fulfilled,
        (state, { payload }) => {
          state.notAssignedOrdersList = payload;
          state.notAssignedOrdersListLoadingStatus = status.SUCCESS;
        }
      )
      .addCase(thunks.getNotAssignedOrdersList.rejected, (state) => {
        state.notAssignedOrdersListLoadingStatus = status.FAIL;
      })

      .addCase(thunks.getNotAssignedOrdersListDelivery.pending, (state) => {
        state.notAssignedOrdersListLoadingStatus = status.PENDING;
      })
      .addCase(
        thunks.getNotAssignedOrdersListDelivery.fulfilled,
        (state, { payload }) => {
          state.notAssignedOrdersList = payload;
          state.notAssignedOrdersListLoadingStatus = status.SUCCESS;
        }
      )
      .addCase(thunks.getNotAssignedOrdersListDelivery.rejected, (state) => {
        state.notAssignedOrdersListLoadingStatus = status.FAIL;
      });
  },
});

const route = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { route };
export default slice.reducer;

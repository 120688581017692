import PropTypes from 'prop-types';

import { ReactComponent as Loader } from 'assets/loader/loader.svg';
import { LoaderWp, Overlay } from './styled';

RLoadingOverlay.propTypes = {
  isVisible: PropTypes.bool,
  bgColor: PropTypes.string,
  children: PropTypes.node,
};

export function RLoadingOverlay({
  isVisible,
  bgColor = 'rgba(255, 255, 255, 0.7)',
  children,
}) {
  return (
    <Overlay
      isVisible={isVisible}
      bgColor={bgColor}
    >
      {children || (
        <LoaderWp>
          <Loader />
        </LoaderWp>
      )}
    </Overlay>
  );
}

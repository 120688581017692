import { createSlice } from '@reduxjs/toolkit';

import { orderStatusAnalytics, agentStatus } from 'utils/const';
import { selectors } from './selectors';
import { thunks } from './thunks';

const analiticGenData = (state, payload) => {
  const { dailyOrdersStatistic, totalEfficiency, totalOrders, couriers } =
    payload;

  function filterRes(target, response) {
    return target.map((el) => {
      const element = response.find((item) => item.status === el.value);
      return {
        ...el,
        total: element?.total || el.total,
      };
    });
  }

  const analytics = filterRes(orderStatusAnalytics, dailyOrdersStatistic);
  const agent = filterRes(agentStatus, couriers);

  state.statisticDoughnut.orderStatus = analytics;
  state.statisticDoughnut.totalEfficiency = totalEfficiency;
  state.statisticDoughnut.totalOrders = totalOrders;
  state.statisticDoughnut.agentStatus = agent;
};

const initialState = {
  statisticDoughnut: {
    orderStatus: [],
    agentStatus: [],
    totalOrders: {},
    totalEfficiency: {},
  },
  heatMap: {
    data: [],
  },
};

const slice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getAnalyticStatistic.fulfilled, (state, { payload }) => {
        analiticGenData(state, payload);
      })
      .addCase(thunks.getStatisticDelivery.fulfilled, (state, { payload }) => {
        analiticGenData(state, payload);
      })
      .addCase(thunks.getAnalyticHeatMap.fulfilled, (state, { payload }) => {
        state.heatMap.data = payload || [];
      });
  },
});

const analytics = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { analytics };

export default slice.reducer;

import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
// eslint-disable-next-line import/no-extraneous-dependencies
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
// eslint-disable-next-line import/no-extraneous-dependencies
import thunk from 'redux-thunk';

// MODULES
import general from 'store/general';
import route from 'store/user/courier/route';
import couriers from 'store/user/couriers';
import order from 'store/user/order';
import orders from 'store/user/orders';
import courier from 'store/user/courier';
import auth from 'store/auth';
import registration from 'store/registration';
import merchant from 'store/user/merchant';
import clients from 'store/user/clients';
import notification from 'store/user/notification';
import analytics from 'store/user/analytics';
import extensions from 'store/user/extension';
import settings from 'store/user/settings';
import merchantDelivery from 'store/delivery/merchant';
import promo from 'store/user/promo';
import courierTracking from 'store/courierTracking';
import taskPricing from 'store/user/taskPricing';
import billing from 'store/user/billing';
import debts from 'store/user/billing/debts';
import restaurantMenu from 'store/user/menu';

const appReducer = combineReducers({
  auth,
  registration,
  general,
  orders,
  order,
  route,
  courier,
  couriers,
  clients,
  merchant,
  notification,
  analytics,
  merchantDelivery,
  extensions,
  settings,
  promo,
  courierTracking,
  taskPricing,
  billing,
  debts,
  restaurantMenu,
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout/fulfilled') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: 'courier',
  storage,
  blacklist: [],
  whitelist: ['auth', 'general', 'merchant'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});

export default store;

const selectors = {
  statusPlans: (state) => state.billing.statusPlans,
  statusPayment: (state) => state.billing.statusPayment,
  statusAddPayment: (state) => state.billing.statusAddPayment,
  statusSubscription: (state) => state.billing.statusSubscription,
  statusUnSubscription: (state) => state.billing.statusUnSubscription,
  statusTransactions: (state) => state.billing.statusTransactions,
  plans: (state) => state.billing.plans,
  payment: (state) => state.billing.payment,
  pagination: (state) => state.billing.pagination,
  transaction: (state) => state.billing.transaction,
  stripeLoading: (state) => state.billing.stripeLoading,
  subscribe3dSecure: (state) => state.billing.subscribe3dSecure,
};

export { selectors };

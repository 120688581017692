import { toast } from 'react-toastify';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const authLogin = createAsyncThunk(
  'auth/login',
  async (userData, { rejectWithValue }) => {
    try {
      const { data } = await api.auth.login(userData);
      return data.data;
    } catch (err) {
      // const { status } = err.response;
      handleServerErrors(err);
      throw rejectWithValue(err.response);
    }
  }
);

const authLogout = createAsyncThunk('auth/logout', async () => {
  try {
    const { data } = await api.auth.logout();
    return data.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const checkCode = createAsyncThunk('auth/checkCode', async (formData) => {
  try {
    const { data } = await api.auth.checkCode(formData);
    return data.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const getCode = createAsyncThunk('auth/getCode', async (formData) => {
  try {
    const { data } = await api.auth.getCode(formData);
    toast.success(data.message);
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const changePassword = createAsyncThunk(
  'auth/changePassword',
  async (formData) => {
    try {
      const { data } = await api.auth.changePassword(formData);
      toast.success(data.message);
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const deleteProfile = createAsyncThunk('auth/deleteProfile', async () => {
  try {
    const { data } = await api.auth.deleteProfile();
    toast.success(data.message);
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const thunks = {
  authLogin,
  authLogout,
  checkCode,
  getCode,
  changePassword,
  deleteProfile,
};

export { thunks };

const selectors = {
  list: (state) => state.couriers.list,
  allCouriersList: (state) => state.couriers.allCouriersList,
  pagination: (state) => state.couriers.pagination,
  filters: (state) => state.couriers.filters,
  couriersLoadingStatus: (state) => state.couriers.couriersLoadingStatus,
  allCouriersLoadingStatus: (state) => state.couriers.allCouriersLoadingStatus,
};

export { selectors };

import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  data: {
    receiveMethod: 'internal',
  },
  assignData: [],
  allMerchants: [{ label: '', value: 0 }],
  orderLoadingStatus: status.IDLE,
  courierAssignStatus: status.IDLE,
  createOrderStatus: status.IDLE,
  deleteOrderStatus: status.IDLE,
  forwardStatus: status.IDLE,
  stuartMerchantStatus: status.IDLE,
  assignStatus: status.IDLE,
};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    CHANGE_STATUS: (state, { payload }) => {
      state.data.status = payload;
    },
    CHANGE_STATUS_FORWARD: (state, { payload }) => {
      state.forwardStatus = payload;
    },
    CLEAR_ORDER_DATA: (state) => {
      state.data = {
        receiveMethod: 'internal',
      };
    },
    CLEAR_MERCHANTS: (state) => {
      state.allMerchants = initialState.allMerchants;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getOrderDetails.pending, (state) => {
        state.orderLoadingStatus = status.PENDING;
      })
      .addCase(thunks.getOrderDetails.fulfilled, (state, { payload }) => {
        state.data = payload.data;
        state.orderLoadingStatus = status.SUCCESS;
      })
      .addCase(thunks.getOrderDetails.rejected, (state) => {
        state.orderLoadingStatus = status.FAIL;
      })

      .addCase(thunks.getOrderDetailsDelivery.pending, (state) => {
        state.orderLoadingStatus = status.PENDING;
      })
      .addCase(
        thunks.getOrderDetailsDelivery.fulfilled,
        (state, { payload }) => {
          state.data = payload.data;
          state.orderLoadingStatus = status.SUCCESS;
        }
      )
      .addCase(thunks.getOrderDetailsDelivery.rejected, (state) => {
        state.orderLoadingStatus = status.FAIL;
      })

      .addCase(thunks.assignOrder.pending, (state) => {
        state.courierAssignStatus = status.PENDING;
      })
      .addCase(thunks.assignOrder.fulfilled, (state) => {
        state.courierAssignStatus = status.SUCCESS;
      })
      .addCase(thunks.assignOrder.rejected, (state) => {
        state.courierAssignStatus = status.FAIL;
      })

      .addCase(thunks.createOrder.pending, (state) => {
        state.createOrderStatus = status.PENDING;
      })
      .addCase(thunks.createOrder.fulfilled, (state) => {
        state.createOrderStatus = status.SUCCESS;
      })
      .addCase(thunks.createOrder.rejected, (state) => {
        state.createOrderStatus = status.FAIL;
      })

      .addCase(thunks.createOrderDelivery.pending, (state) => {
        state.createOrderStatus = status.PENDING;
      })
      .addCase(thunks.createOrderDelivery.fulfilled, (state) => {
        state.createOrderStatus = status.SUCCESS;
      })
      .addCase(thunks.createOrderDelivery.rejected, (state) => {
        state.createOrderStatus = status.FAIL;
      })

      .addCase(thunks.deleteOrder.pending, (state) => {
        state.deleteOrderStatus = status.PENDING;
      })
      .addCase(thunks.deleteOrder.fulfilled, (state) => {
        state.deleteOrderStatus = status.SUCCESS;
      })
      .addCase(thunks.deleteOrder.rejected, (state) => {
        state.deleteOrderStatus = status.FAIL;
      })
      .addCase(thunks.getAllMerchant.pending, (state) => {
        state.stuartMerchantStatus = status.PENDING;
      })
      .addCase(thunks.getAllMerchant.fulfilled, (state, { payload }) => {
        state.allMerchants = payload?.map((el) => {
          return {
            value: el.id,
            label: el.title,
          };
        });
        state.stuartMerchantStatus = status.SUCCESS;
      })
      .addCase(thunks.getAllMerchant.rejected, (state) => {
        state.stuartMerchantStatus = status.FAIL;
      })
      .addCase(thunks.stuartForward.pending, (state) => {
        state.forwardStatus = status.PENDING;
      })
      .addCase(thunks.stuartForward.fulfilled, (state) => {
        state.forwardStatus = status.SUCCESS;
      })
      .addCase(thunks.stuartForward.rejected, (state) => {
        state.forwardStatus = status.FAIL;
      })
      .addCase(thunks.uberEatsForward.pending, (state) => {
        state.forwardStatus = status.PENDING;
      })
      .addCase(thunks.uberEatsForward.fulfilled, (state) => {
        state.forwardStatus = status.SUCCESS;
      })
      .addCase(thunks.uberEatsForward.rejected, (state) => {
        state.forwardStatus = status.FAIL;
      })
      .addCase(thunks.catcherForward.pending, (state) => {
        state.forwardStatus = status.PENDING;
      })
      .addCase(thunks.catcherForward.fulfilled, (state) => {
        state.forwardStatus = status.SUCCESS;
      })
      .addCase(thunks.catcherForward.rejected, (state) => {
        state.forwardStatus = status.FAIL;
      })
      .addCase(thunks.getAssignMethod.pending, (state) => {
        state.assignStatus = status.PENDING;
      })
      .addCase(thunks.getAssignMethod.fulfilled, (state, { payload }) => {
        state.assignData = payload;
        state.assignStatus = status.SUCCESS;
      })
      .addCase(thunks.getAssignMethod.rejected, (state) => {
        state.assignStatus = status.FAIL;
      });
  },
});

const order = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { order };
export default slice.reducer;

import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';

export const courier = {
  getCouriers(params) {
    return http.get(WEB_API_ROUTES.user.courier.getCouriers, {
      params,
    });
  },
  getCouriersDelivery(params) {
    return http.get(WEB_API_ROUTES.user.courier.getAllCouriersDelivery, {
      params,
    });
  },
  getAllCouriers(params) {
    return http.get(WEB_API_ROUTES.user.courier.getAllCouriers, { params });
  },
  getAllCouriersDelivery(params) {
    return http.get(WEB_API_ROUTES.user.courier.getAllCouriersDelivery, {
      params,
    });
  },
  getCourierById({ params, id }) {
    return http.get(
      WEB_API_ROUTES.user.courier.getCourierById.replace('{courier}', id),
      { params }
    );
  },
  getCourierByIdAllOrders({ params, id }) {
    return http.get(
      WEB_API_ROUTES.user.courier.getCourierByIdAllOrders.replace(
        '{courier}',
        id
      ),
      { params }
    );
  },
  getCourierAssignedOrders(params) {
    return http.get(WEB_API_ROUTES.user.courier.getCourierAssignedOrders, {
      params,
    });
  },
  sendOrderToCourier(params) {
    return http.post(WEB_API_ROUTES.user.courier.sendOrderToCourier, params);
  },
  cancelAssignments(params) {
    return http.post(WEB_API_ROUTES.user.courier.cancelAssignments, params);
  },
  createCourier(params) {
    return http.post(WEB_API_ROUTES.user.courier.createCourier, params);
  },
  createCourierDelivery(params) {
    return http.post(WEB_API_ROUTES.user.courier.createCourierDelivery, params);
  },
  editCourier({ params, id }) {
    return http.put(
      WEB_API_ROUTES.user.courier.editCourier.replace('{courier}', id),
      params
    );
  },
  deleteCourier(id) {
    return http.delete(
      WEB_API_ROUTES.user.courier.deleteCourier.replace('{courier}', id)
    );
  },
};

import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { pagination } from 'utils/prepare';
import { selectors } from './selectors';
import { thunks } from './thunks';

const initialState = {
  list: [],
  debtInfo: null,
  pagination: {
    current: 1,
    perPage: 7,
    total: 1,
  },
  statusDebts: status.IDLE,
  statusDebtInfo: status.IDLE,
  statusPayDebt: status.IDLE,
};

const slice = createSlice({
  name: 'debts',
  initialState,
  reducers: {
    RESET_DEBT_INFO: (state) => {
      state.debtInfo = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getDebts.pending, (state) => {
        state.statusDebts = status.PENDING;
      })
      .addCase(thunks.getDebts.fulfilled, (state, { payload }) => {
        const { data, meta } = payload;
        const { current_page, from, to, total, per_page } = meta;
        state.pagination = pagination(current_page, from, to, total, per_page);

        state.list = data;
        state.statusDebts = status.SUCCESS;
      })
      .addCase(thunks.getDebts.rejected, (state) => {
        state.statusDebts = status.FAIL;
      })

      .addCase(thunks.getDebtInfo.pending, (state) => {
        state.statusDebtInfo = status.PENDING;
      })
      .addCase(thunks.getDebtInfo.fulfilled, (state, { payload }) => {
        state.debtInfo = payload.data;
        state.statusDebtInfo = status.SUCCESS;
      })
      .addCase(thunks.getDebtInfo.rejected, (state) => {
        state.statusDebtInfo = status.FAIL;
      })

      .addCase(thunks.payDebt.pending, (state) => {
        state.statusPayDebt = status.PENDING;
      })
      .addCase(thunks.payDebt.fulfilled, (state) => {
        state.statusPayDebt = status.SUCCESS;
      })
      .addCase(thunks.payDebt.rejected, (state) => {
        state.statusPayDebt = status.FAIL;
      });
  },
});

const debts = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { debts };

export default slice.reducer;

import { css } from '@emotion/react';

export const GlobalStyles = css`
  body {
    font-family: 'Roboto', sans-serif;

    .Toastify__toast-container {
      word-break: break-word;
    }
  }
`;

const selectors = {
  statusDebts: (state) => state.debts.statusDebts,
  statusDebtInfo: (state) => state.debts.statusDebtInfo,
  statusPayDebt: (state) => state.debts.statusPayDebt,
  pagination: (state) => state.debts.pagination,
  list: (state) => state.debts.list,
  data: (state) => state.debts.debtInfo,
};

export { selectors };

import { toast } from 'react-toastify';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getExtensions = createAsyncThunk('getExtensions', async () => {
  try {
    const { data } = await api.extensions.getExtensions();
    return data?.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const getExtensionsDelivery = createAsyncThunk(
  'getExtensionsDelivery',
  async () => {
    try {
      const { data } = await api.extensions.getExtensionsDelivery();
      return data?.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const deleteExtensions = createAsyncThunk('deleteExtensions', async (id) => {
  try {
    const { data } = await api.extensions.deleteExtensions(id);
    return data?.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const deleteExtensionsDelivery = createAsyncThunk(
  'deleteExtensions/delivery',
  async (id) => {
    try {
      await api.extensions.deleteExtensionsDelivery(id);
      toast.success('success');
      return id;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const connectExtensions = createAsyncThunk(
  'connectExtensions',
  async (formData) => {
    try {
      const { data } = await api.extensions.connectExtensions(formData);
      return data?.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const connectExtensionsDelivery = createAsyncThunk(
  'connectExtensionsDelivery',
  async (formData) => {
    try {
      const { data } = await api.extensions.connectExtensionsDelivery(formData);
      return data?.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getMerchantsFlipDish = createAsyncThunk(
  'getMerchantsFlipDish',
  async (formData) => {
    try {
      const { data } = await api.extensions.getMerchantsFlipDish(formData);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getExtensions,
  deleteExtensions,
  connectExtensions,
  getMerchantsFlipDish,
  getExtensionsDelivery,
  deleteExtensionsDelivery,
  connectExtensionsDelivery,
};

export { thunks };

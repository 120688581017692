import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  data: {
    latitude: 0,
    longitude: 0,
  },
  emailMerchant: '',
  updatedAddress: {
    latitude: 0,
    longitude: 0,
    address: null,
    city: null,
  },
  editInfoStatus: status.IDLE,
  dataFetchingStatus: status.IDLE,
  passwordChangingStatus: status.IDLE,
};

const slice = createSlice({
  name: 'merchant',
  initialState,
  reducers: {
    RESET_STATUS: (state, { payload }) => {
      state[payload] = status.IDLE;
    },
    UPDATE_ADDRESS: (state, { payload }) => {
      state.updatedAddress.latitude = payload.coordinates.lat;
      state.updatedAddress.longitude = payload.coordinates.lng;
      state.updatedAddress.address = payload.address;
      state.updatedAddress.city = payload.city;
    },
    CLEAR_MERCHANT: (state) => {
      state.updatedAddress = {};
      state.data = {
        latitude: 0,
        longitude: 0,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getMerchantInfo.pending, (state) => {
        state.dataFetchingStatus = status.PENDING;
      })
      .addCase(
        thunks.getMerchantInfo.fulfilled,
        (state, { payload: { data } }) => {
          state.data = data;
          state.emailMerchant = data.email;
          state.dataFetchingStatus = status.SUCCESS;
        }
      )
      .addCase(thunks.getMerchantInfo.rejected, (state) => {
        state.dataFetchingStatus = status.FAIL;
      })

      .addCase(thunks.editMerchantInfo.pending, (state) => {
        state.editInfoStatus = status.PENDING;
      })
      .addCase(thunks.editMerchantInfo.fulfilled, (state, { payload }) => {
        console.log(payload);
        state.editInfoStatus = status.SUCCESS;
      })
      .addCase(thunks.editMerchantInfo.rejected, (state) => {
        state.editInfoStatus = status.FAIL;
      })

      .addCase(thunks.changePassword.pending, (state) => {
        state.passwordChangingStatus = status.PENDING;
      })
      .addCase(thunks.changePassword.fulfilled, (state) => {
        state.passwordChangingStatus = status.SUCCESS;
      })
      .addCase(thunks.changePassword.rejected, (state) => {
        state.passwordChangingStatus = status.FAIL;
      });
  },
});

const merchant = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { merchant };
export default slice.reducer;

export const WEB_API_ROUTES = {
  auth: {
    login: '/login',
    logout: '/logout',
    checkCode: '/check-code',
    getCode: '/forgot-password',
    changePassword: '/update-forgotten-password',
    deleteProfile: '/delete-profile',
  },

  registration: {
    register: '/register',
    getBusiness: '/list/business-types',
    verifyAccount: '/verify',
  },

  settings: {
    getSettings: 'settings/courier-assignment',
    addSettings: 'settings/courier-assignment',
    updateSettings: 'settings/courier-assignment/{id}',
    disableSettings: 'settings/disable-settings',
    getPromo: 'settings/promo',
    updatePromo: 'settings/promo/{id}',
    deactivatePromo: 'settings/promo/deactivate',
    pricing: 'pricing',
    deleteRulesPricing: '/pricing/{pricing}',
    getRulesId: '/pricing/{pricing}',
    setDefaultRulesPricing: '/pricing/set-as-default/{pricing}',
  },

  courierTracking: {
    getInfoCourier: 'courier-location',
  },

  billing: {
    getPlans: 'plans',
    addPaymentsMethod: 'user/add-payment-method',
    getPaymentsMethod: 'subscription/current-subscription',
    billingSubscription: 'subscription/subscribe',
    unSubscription: 'subscription/unsubscribe',
    getTransactions: 'transactions',
    getDebts: '/debts',
    getDebtInfo: '/debts/{debt}',
    payDebt: '/debts/{debt}',
  },

  user: {
    getOrders: '/orders',
    getOrdersDelivery: '/orders-for-delivery',
    getOrdersFilter: '/filtered-orders',
    getNotAssignedOrders: '/not-assigned-orders-for-merchant',
    getNotAssignedOrdersListDelivery: '/not-assigned-orders-for-delivery',
    getMerchantInfo: '/user-info',
    editMerchantInfo: '/merchant/{merchant}',
    editDeliveryInfo: '/delivery/{delivery}',
    changePassword: '/change-password',
    order: {
      getOrderDetails: '/orders/{order}',
      getCouriers: '/courier',
      assignOrder: '/assign-order-to-courier',
      createOrder: '/orders',
      deleteOrder: '/orders/{order}',
      cancelOrder: '/orders/{order}',
      getAllDotsOrders: '/orders/small',
      getAllDotsCourier: '/courier/small',
      getAllMerchant: '/all-merchants-for-delivery',
      stuartForward: '/stuart/forward/{order}',
      uberEatsForward: '/uber/forward/{order}',
      catcherForward: '/catcher/forward/{order}',
      assingMethods: '/orders/{order}/delivery-estimation',
    },
    deliveryOrder: {
      getOrderDetails: '/orders-for-delivery/{order}',
    },
    courier: {
      getCouriers: '/courier',
      getAllCouriers: '/all-couriers',
      getCourierById: '/courier/{courier}',
      getCourierByIdAllOrders: '/courier/with-all-orders/{courier}',
      createCourier: '/courier',
      editCourier: '/courier/{courier}',
      deleteCourier: '/courier/{courier}',
      getCourierAssignedOrders: '/assigned-orders-for-courier',
      sendOrderToCourier: '/send-order-to-courier',
      cancelAssignments: '/drop-courier-from-orders',
      getAllCouriersDelivery: '/couriers-for-delivery',
      createCourierDelivery: '/store-courier-by-delivery',
    },
    notification: {
      getNotification: '/notification',
      getNotificationCount: '/notification-count',
      readNotification: '/notification-mark-read',
      saveUserIdWebPush: '/save-firebase-token',
      deleteUserIdWebPush: '/delete-firebase-token',
    },
    clients: {
      getClients: '/customer',
    },
    clientsDelivery: {
      getClientsDelivery: '/customers-for-delivery',
    },
    analytics: {
      statistic: '/real-time-statistic',
      heatMap: '/heatmap',
    },
    orderDelivery: {
      createOrderDelivery: '/orders-for-delivery',
      getAllDotsCourierDelivery: '/courier-for-delivery/small',
    },
    analyticsDelivery: {
      statistic: '/real-time-statistic-for-delivery',
    },
    menu: {
      getMenu: '/menu',
      getCategoryMenu: '/menu-category/{menu_category}',
      createCategory: '/menu/{menu}/menu-category',
      deleteCategory: '/menu-category/{menu_category}',
      updateCategory: '/menu-category/{menu_category}',
      createDish: '/menu-category/{menu_category}/menu-dish',
      deleteDish: '/menu-dish/{menu_dish}',
      menuExport: '/menu/export',
      dishExport: '/menu-dish/{menuDish}/export',
    },
    extensions: {
      integration: '/integration',
      integrationDelivery: '/delivery/integration',
      integrationUpdate: '/integration/{integration}',
      integrationUpdateDelivery: '/delivery/integration/{integration}',
      getMerchantsFlipDish: '/integration/flipdish-stores',
    },
  },
  merchantDelivery: {
    createMerchant: '/merchant',
    deleteMerchant: '/merchant/{merchant}',
    getMerchantId: '/merchant/{id}',
    updateMerchant: '/merchant/{merchant}',
    updateMerchantPsw: '/update-merchant-password-by-delivery',
    getMerchant: '/merchants-for-delivery',
  },
};

import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  dataBusiness: {
    merchant: [],
    delivery: [],
  },
  registerStatus: status.IDLE,
  getDataBusinessStatus: status.IDLE,
};

const slice = createSlice({
  name: 'registration',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(thunks.register.pending, (state) => {
        state.registerStatus = status.PENDING;
      })
      .addCase(thunks.register.fulfilled, (state) => {
        state.registerStatus = status.SUCCESS;
      })
      .addCase(thunks.register.rejected, (state) => {
        state.registerStatus = status.FAIL;
      })
      .addCase(thunks.getDataBusiness.pending, (state) => {
        state.getDataBusinessStatus = status.PENDING;
      })
      .addCase(thunks.getDataBusiness.fulfilled, (state, { payload }) => {
        const generateValueSelect = (arr) => {
          return arr?.map((item) => {
            return { label: item, value: item };
          });
        };
        state.dataBusiness.delivery = generateValueSelect(payload.delivery);
        state.dataBusiness.merchant = generateValueSelect(payload.merchant);
        state.getDataBusinessStatus = status.SUCCESS;
      })
      .addCase(thunks.getDataBusiness.rejected, (state) => {
        state.getDataBusinessStatus = status.FAIL;
      });
  },
});

const registration = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { registration };
export default slice.reducer;

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { auth } from 'store/auth';
import { appRoles } from 'utils/roles';
import { ReactComponent as LogoDelivery } from 'assets/img/logo/logoDelivery.svg';
import { ReactComponent as LogoMerchant } from 'assets/img/logo/logoMerchant.svg';
import { ReactComponent as LogoMerchantSmall } from 'assets/img/logo/logoMerchantSmall.svg';
import { ReactComponent as LogoSmallDelivery } from 'assets/img/logo/logoSmallDelivery.svg';
import { LogoWrap, LogoContainer } from './styled';

AppLogo.propTypes = {
  fullSize: PropTypes.bool,
};

export function AppLogo({ fullSize }) {
  const role = useSelector(auth.selectors.role);

  if (!role) {
    return (
      <LogoWrap>
        <LogoContainer>
          <LogoMerchant />
        </LogoContainer>
      </LogoWrap>
    );
  }

  return (
    <LogoWrap>
      {appRoles.DELIVERY === role && (
        <LogoContainer fullSize={fullSize}>
          {fullSize ? <LogoDelivery /> : <LogoSmallDelivery />}
        </LogoContainer>
      )}
      {appRoles.USER === role && (
        <LogoContainer fullSize={fullSize}>
          {fullSize ? <LogoMerchant /> : <LogoMerchantSmall />}
        </LogoContainer>
      )}
    </LogoWrap>
  );
}

import PropTypes from 'prop-types';
import { ThemeProvider } from '@emotion/react';
import { useState } from 'react';

const theme = {
  LIGHT: {
    btnLightBlue: '#E4EAFF',
    green: '#e7ffea',
    greenDark: '#30b756',
    blue: '#e2f1ff',
    blueDark: '#2f80ed',
    error: '#EB5757',
    sunsetOrange: '#FF5555',
    bgTabs: '#e4e3ff',
    deliveryBg: '#00000000',
    orange: '#FF8D3A',
    oldLace: '#FFF4E7',
    mangoOrange: '#FF8D3A',
    purpleOpasity: 'rgba(140, 96, 227, 0.25)',
    purple: '#8C60E3',
    backgroundDelivery:
      'linear-gradient(220.98deg, #3F4050 46.49%, #2E2E42 100%);',
    backgroundReg: '#FAFBFD',
    background:
      'radial-gradient(circle, rgba(244,247,255,1) 0%, rgba(225,226,255,1) 100%)',
    gray: {
      1: '#f2f2f2',
      2: '#e4e4e4',
      3: '#d7d7d7',
      4: '#c9c9c9',
      5: '#aeaeae',
      6: '#939393',
      7: '#787878',
      8: '#606060',
      9: '#484848',
      10: '#bdbdbd',
      11: '#dfdfdf',
      12: '#e0e0e0',
      13: '#989898',
      14: '#242424',
      15: '#3F465F',
      16: '#585A60',
      17: '#444444',
      18: '#C4C4C4',
      19: '#D9D9D9',
      20: '#fafafa',
    },
    paleSky: '#3f4050',
    lightGold: '#FFDF39',

    table: {
      hover: '#f3f5f9',
      headerColor: '#606060',
      rowColor: '#000000',
    },

    analytics: {
      num: '#5570FF',
      newOrder: '#C9CBCD',
      delayedPickup: '#F7D06C',
      orderAssigned: '#F1A354',
      orderSent: '#569FE5',
      onTheWay: '#9269F6',
      delivered: '#6DBDBF',
      notDelivered: '#FF5555',
      Busy: '#F7D06C',
      Free: '#9269F6',
      Inactive: '#C9CBCD',
    },

    timePicker: {
      violet: '#8c60e3',
    },

    statusChips: {
      hover: '#d7d7d7',
      color: '#606060',
      borderDefault: '#ffffff',
      backgroundDefault: '#f2f2f2',
      backgroundActive: '#e2f1ff',
      borderActive: '#2f80ed',
      borderActiveDeliv: '#96ADFF',
      iconColor: '#96ADFF',
      deliveryIconColor: '#2F80ED',
      iconColorDark: '#43529D',
    },

    font: {
      primary: '#484848',
      title: '#303030',
      alternative: '#FFFFFF',
      sizeSm: '12px',
      sizeMd: '14px',
      sizeLg: '16px',
    },

    title: {
      sizeSm: '18px',
      sizeMd: '22px',
      sizeLg: '24px',
    },

    field: {
      labelColor: '#939393',
      labelBgColor: '#FFFFFF',
      textColor: '#474747',
      bgColor: '#FFFFFF',
      borderColor: '#d7d7d7',
    },

    button: {
      primary: {
        color: '#ffffff',
        border: '#ffffff',
        bgHover:
          'linear-gradient(180deg, #D6A3FF 0%, #793FED 70%, #B691FF 100%)',
      },
      alternative: {
        color: '#303030',
        borderOutlined: '#7460ef',
        bgLight: 'linear-gradient(180deg, #EDEFFF 0%, #D8DBF7 100%)',
        bgHover:
          'linear-gradient(180deg, #EDEFFF 0%, #9A92FF80 50%, #D8DBF7 100%)',
      },
      simple: {
        color: '#2B3234',
        bgLight: '#F3F5F9',
        bgDark: '#D7D7D7',
      },
    },

    billing: {
      banner: {
        background: '#FF5555',
      },
      popup: {
        title: '#303030',
        headerBg: '#8c60e3',
        badgeBg: '#FF8D3A',
        badgeColor: '#FFFFFF',
        infoBg: '#F2F2F2',
        infoColor: '#606060',
        infoColorSpan: '#303030',
        totalAmount: '#2f80ed',
      },
    },
  },
  DARK: {},
};

Theme.propTypes = {
  children: PropTypes.node,
};

function Theme({ children }) {
  const [mode] = useState('LIGHT');
  return <ThemeProvider theme={theme[mode]}>{children}</ThemeProvider>;
}

export default Theme;

const selectors = {
  count: (state) => state.notification.newNotificationCount,
  notification: (state) => state.notification.notification,
  currentPage: (state) => state.notification.pagination.currentPage,
  lastPage: (state) => state.notification.pagination.lastPage,
  date: (state) => state.notification.params.date,
  notificationIdsChecked: (state) => state.notification.notificationIdsChecked,
  stateOldIdNotif: (state) => state.notification.stateOldIdNotif,
  statusNotification: (state) => state.notification.statusNotification,
};

export { selectors };

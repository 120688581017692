import styled from '@emotion/styled';

const Wrapper = styled.div`
  display: inline-block;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  &:after {
    content: ' ';
    display: block;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    border-radius: 50%;
    border: ${({ border, color, theme }) =>
      `${border}px solid ${!color ? theme.gray[14] : color}`} ;
    border-color: ${({ color, theme }) =>
      !color
        ? `${theme.gray[14]} transparent ${theme.gray[14]} transparent`
        : `${color} transparent ${color} transparent`} ;
    animation: lds-dual-ring 0.8s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`;

export { Wrapper };

import { format } from 'date-fns';
import { appTimeFormat } from 'utils/formats';

const prepareFormData = (data) => {
  const form = new FormData();
  Object.entries(data).forEach((elem) => {
    if (elem[1] !== null && elem[1] !== undefined) {
      form.append(elem[0], elem[1]);
    }
  });
  return form;
};

const transformNaN = (value) => (Number.isNaN(value) ? null : value);

const flagViewPlates = (value) => {
  return value !== 'pedestrian' && value !== 'bicycle';
};

const formatTime = (time) => {
  if (time && typeof time === 'number')
    return format(time * 1000, `${appTimeFormat}`);
  return undefined;
};

const pagination = (current, from, to, total, perPage) => {
  return {
    current,
    from,
    to,
    total,
    perPage,
  };
};

const position = (lat, lng) => {
  return [lat, lng];
};

const clearEmptyValuesInObject = (obj) => {
  const result = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(obj)) {
    if (value) {
      result[key] = value;
    }
  }

  return result;
};

export {
  prepareFormData,
  transformNaN,
  formatTime,
  flagViewPlates,
  clearEmptyValuesInObject,
  pagination,
  position,
};

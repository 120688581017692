import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';
import { toast } from 'react-toastify';

const getPricingRules = createAsyncThunk(
  'taskPricing/getPricingRules',
  async (params) => {
    try {
      const { data } = await api.pricing.getPricingRules(params);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const addRulesPricing = createAsyncThunk(
  'taskPricing/addRulesPricing',
  async (formData) => {
    try {
      const { data } = await api.pricing.addRulesPricing(formData);
      toast.success('rule was added successfully');
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const updateRulesPricing = createAsyncThunk(
  'taskPricing/updateRulesPricing',
  async (formData) => {
    try {
      const { data } = await api.pricing.updateRulesPricing(formData);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const deleteRulesPricing = createAsyncThunk(
  'taskPricing/deleteRulesPricing',
  async (id) => {
    try {
      const { data } = await api.pricing.deleteRulesPricing(id);
      toast.success(data.message);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getRulesId = createAsyncThunk('taskPricing/getRulesId', async (id) => {
  try {
    const { data } = await api.pricing.getRulesId(id);
    toast.success(data.message);
    return data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const setDefaultRulesPricing = createAsyncThunk(
  'taskPricing/setDefaultRulesPricing',
  async (id) => {
    try {
      const { data } = await api.pricing.setDefaultRulesPricing(id);
      toast.success(data.message);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getPricingRules,
  addRulesPricing,
  updateRulesPricing,
  deleteRulesPricing,
  getRulesId,
  setDefaultRulesPricing,
};

export { thunks };

const selectors = {
  list: (state) => state.merchantDelivery.list,
  address: (state) => state.merchantDelivery.address,
  dataForm: (state) => state.merchantDelivery.saveValueForm,
  merchant: (state) => state.merchantDelivery.merchant,
  statusGerMerchant: (state) => state.merchantDelivery.statusGerMerchant,
  statusDelete: (state) => state.merchantDelivery.statusDelete,
  merchantExtensions: (state) => state.merchantDelivery.merchantExtensions,
  pagination: (state) => state.merchantDelivery.pagination,
  deliveryLoadingStatus: (state) =>
    state.merchantDelivery.deliveryLoadingStatus,
  connectedStatus: (state) => state.merchantDelivery.connectedStatus,
};

export { selectors };

import { WEB_API_ROUTES } from 'api/api-routes';
import http from 'api/http';

export const user = {
  getOrders(params) {
    return http.get(WEB_API_ROUTES.user.getOrders, { params });
  },
  getOrdersDelivery(params) {
    return http.get(WEB_API_ROUTES.user.getOrdersDelivery, { params });
  },
  getOrdersFilter(params) {
    return http.get(WEB_API_ROUTES.user.getOrdersFilter, { params });
  },
  getNotAssignedOrders() {
    return http.get(WEB_API_ROUTES.user.getNotAssignedOrders);
  },
  getNotAssignedOrdersListDelivery() {
    return http.get(WEB_API_ROUTES.user.getNotAssignedOrdersListDelivery);
  },
  getMerchantInfo() {
    return http.get(WEB_API_ROUTES.user.getMerchantInfo);
  },
  editMerchantInfo({ formData, id }) {
    return http.put(
      WEB_API_ROUTES.user.editMerchantInfo.replace('{merchant}', id),
      formData
    );
  },
  editDeliveryInfo({ formData, id }) {
    return http.put(
      WEB_API_ROUTES.user.editDeliveryInfo.replace('{delivery}', id),
      formData
    );
  },
  changePassword(data) {
    return http.post(WEB_API_ROUTES.user.changePassword, data);
  },
  getNotification(params) {
    return http.get(`${WEB_API_ROUTES.user.notification.getNotification}`, {
      params,
    });
  },
  getNotificationCount() {
    return http.get(WEB_API_ROUTES.user.notification.getNotificationCount);
  },
  readNotification(data) {
    return http.post(WEB_API_ROUTES.user.notification.readNotification, data);
  },
  saveUserIdWebPush(data) {
    return http.post(WEB_API_ROUTES.user.notification.saveUserIdWebPush, data);
  },
  deleteUserIdWebPush(data) {
    return http.post(
      WEB_API_ROUTES.user.notification.deleteUserIdWebPush,
      data
    );
  },
  getAnalyticStatistic() {
    return http.get(WEB_API_ROUTES.user.analytics.statistic);
  },
  getStatisticDelivery() {
    return http.get(WEB_API_ROUTES.user.analyticsDelivery.statistic);
  },

  getAnalyticHeatMap(params) {
    return http.get(WEB_API_ROUTES.user.analytics.heatMap, { params });
  },

  getAllDotsOrders() {
    return http.get(WEB_API_ROUTES.user.order.getAllDotsOrders);
  },

  getAllDotsCourier() {
    return http.get(WEB_API_ROUTES.user.order.getAllDotsCourier, {
      params: { status: 'online' },
    });
  },

  getAllDotsCourierDelivery() {
    return http.get(
      WEB_API_ROUTES.user.orderDelivery.getAllDotsCourierDelivery,
      {
        params: { status: 'online' },
      }
    );
  },
};

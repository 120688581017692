const selectors = {
  dataMap: (state) => state.courierTracking.dataMap,
  courier: (state) => state.courierTracking.courier,
  promo: (state) => state.courierTracking.promo,
  statusOrderInfo: (state) => state.courierTracking.statusOrderInfo,
  statusDelivered: (state) => state.courierTracking.statusDelivered,
  statusNotDelivered: (state) => state.courierTracking.statusNotDelivered,
};

export { selectors };

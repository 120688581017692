import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';

import { AppLogo } from 'components/AppLogo';
import { RTextField } from 'components/UI/RTextField';
import { RButton } from 'components/UI/RButton';
import { RLoadingOverlay } from 'components/common/RLoadingOverlay';
import { status } from 'utils/const';
import { appRoles } from 'utils/roles';
import { auth } from 'store/auth';
import { defaultValues, schema } from './config';
import {
  Form,
  LogoContainer,
  ButtonContainer,
  Layout,
  ForgotPassword,
} from './styled';

function SignInPage() {
  const dispatch = useDispatch();
  const loginStatus = useSelector(auth.selectors.loginStatus);
  const { control, handleSubmit } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const { payload } = await dispatch(auth.thunks.authLogin(data));

    dispatch(auth.actions.SET_LOGIN(payload));
  };

  return (
    <RoleChecker>
      <Layout>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <RLoadingOverlay isVisible={loginStatus === status.PENDING} />
          <LogoContainer>
            <AppLogo />
          </LogoContainer>
          <RTextField
            label="Email"
            name="email"
            control={control}
            fullWidth
          />
          <RTextField
            label="Password"
            name="password"
            control={control}
            type="password"
            fullWidth
          />
          <ButtonContainer>
            <RButton
              type="submit"
              height={40}
            >
              Log in
            </RButton>
          </ButtonContainer>
          <Link to="/forgot-password">
            <ForgotPassword>Forgot password?</ForgotPassword>
          </Link>
        </Form>
      </Layout>
    </RoleChecker>
  );
}

RoleChecker.propTypes = {
  children: PropTypes.node,
};

function RoleChecker({ children }) {
  const token = useSelector(auth.selectors.token);
  const role = useSelector(auth.selectors.role);
  if (token && role === appRoles.USER) return <Redirect to="/user/orders" />;
  if (token && role === appRoles.DELIVERY)
    return <Redirect to="/delivery/orders" />;
  return children;
}

export default SignInPage;

import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { pagination } from 'utils/prepare';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  list: [],
  pagination: {},
  clientsLoadingStatus: status.PENDING,
};

const slice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    CLEAR_STATUS: (state) => {
      state.clientsLoadingStatus = status.PENDING;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getClients.pending, (state) => {
        state.clientsLoadingStatus = status.PENDING;
      })
      .addCase(thunks.getClients.fulfilled, (state, { payload }) => {
        const {
          meta: { current_page, from, to, total, per_page },
          data,
        } = payload;
        state.list = data;
        state.pagination = pagination(current_page, from, to, total, per_page);
        state.clientsLoadingStatus = status.SUCCESS;
      })
      .addCase(thunks.getClients.rejected, (state) => {
        state.clientsLoadingStatus = status.FAIL;
      })

      .addCase(thunks.getClientsDelivery.pending, (state) => {
        state.clientsLoadingStatus = status.PENDING;
      })
      .addCase(thunks.getClientsDelivery.fulfilled, (state, { payload }) => {
        const {
          meta: { current_page, from, to, total, per_page },
          data,
        } = payload;
        state.list = data;
        state.pagination = pagination(current_page, from, to, total, per_page);
        state.clientsLoadingStatus = status.SUCCESS;
      })
      .addCase(thunks.getClientsDelivery.rejected, (state) => {
        state.clientsLoadingStatus = status.FAIL;
      });
  },
});

const clients = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { clients };
export default slice.reducer;

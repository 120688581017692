import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { pagination } from 'utils/prepare';
import { selectors } from './selectors';
import { thunks } from './thunks';

const initialState = {
  subscribe3dSecure: false,
  plans: [],
  payment: {
    card: { brand: '', last4: '' },
    subscription: null,
  },
  transaction: [],
  pagination: {
    current: 1,
    perPage: 7,
    total: 1,
  },
  statusPlans: status.IDLE,
  statusPayment: status.IDLE,
  statusAddPayment: status.IDLE,
  statusSubscription: status.IDLE,
  statusUnSubscription: status.IDLE,
  statusTransactions: status.IDLE,
  stripeLoading: status.IDLE,
};

const slice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    CLEAR_STATUS_ADD: (state) => {
      state.statusAddPayment = status.IDLE;
    },
    CLEAR_STATUS_SBC: (state) => {
      state.statusSubscription = status.IDLE;
    },
    CLEAR_STATUS_UN_SBC: (state) => {
      state.statusUnSubscription = status.IDLE;
    },
    SET_CARD_INFO: (state, { payload }) => {
      state.payment.card = payload;
    },
    SET_IS_ACTIVE: (state, { payload }) => {
      state.payment.subscription.isActive = payload;
    },
    SET_STRIPE_LOADING: (state, { payload }) => {
      state.stripeLoading = payload;
    },
    SET_STRIPE_3DS: (state, { payload }) => {
      state.subscribe3dSecure = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getPlansBilling.pending, (state) => {
        state.statusPlans = status.PENDING;
      })
      .addCase(thunks.getPlansBilling.fulfilled, (state, { payload }) => {
        state.plans = payload.data;
        state.statusPlans = status.SUCCESS;
      })
      .addCase(thunks.getPlansBilling.rejected, (state) => {
        state.statusPlans = status.FAIL;
      })
      .addCase(thunks.getPaymentsMethod.pending, (state) => {
        state.statusPayment = status.PENDING;
      })
      .addCase(thunks.getPaymentsMethod.fulfilled, (state, { payload }) => {
        state.payment = payload;
        state.statusPayment = status.SUCCESS;
      })
      .addCase(thunks.getPaymentsMethod.rejected, (state) => {
        state.statusPayment = status.FAIL;
      })
      .addCase(thunks.addPaymentsMethod.pending, (state) => {
        state.statusAddPayment = status.PENDING;
      })
      .addCase(thunks.addPaymentsMethod.fulfilled, (state) => {
        state.statusAddPayment = status.SUCCESS;
      })
      .addCase(thunks.addPaymentsMethod.rejected, (state) => {
        state.statusAddPayment = status.FAIL;
      })
      .addCase(thunks.billingSubscription.pending, (state) => {
        state.statusSubscription = status.PENDING;
      })
      .addCase(thunks.billingSubscription.fulfilled, (state, { payload }) => {
        if (payload?.message === '3dsRequired') {
          state.subscribe3dSecure = payload?.params?.clientSecret;
        }
        state.statusSubscription = status.SUCCESS;
      })
      .addCase(thunks.billingSubscription.rejected, (state) => {
        state.statusSubscription = status.FAIL;
      })
      .addCase(thunks.unSubscription.pending, (state) => {
        state.statusUnSubscription = status.PENDING;
      })
      .addCase(thunks.unSubscription.fulfilled, (state) => {
        state.statusUnSubscription = status.SUCCESS;
      })
      .addCase(thunks.unSubscription.rejected, (state) => {
        state.statusUnSubscription = status.FAIL;
      })
      .addCase(thunks.getTransactions.pending, (state) => {
        state.statusTransactions = status.PENDING;
      })
      .addCase(thunks.getTransactions.fulfilled, (state, { payload }) => {
        const { data, meta } = payload;
        const { current_page, from, to, total, per_page } = meta;
        state.pagination = pagination(current_page, from, to, total, per_page);

        state.transaction = data;
        state.statusTransactions = status.SUCCESS;
      })
      .addCase(thunks.getTransactions.rejected, (state) => {
        state.statusTransactions = status.FAIL;
      });
  },
});

const billing = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { billing };

export default slice.reducer;

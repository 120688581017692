import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getOrderDetails = createAsyncThunk(
  'order/getOrderDetails',
  async ({ id }) => {
    try {
      const { data } = await api.order.getOrderDetails(id);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getOrderDetailsDelivery = createAsyncThunk(
  'order/getOrderDetailsDelivery',
  async ({ id }) => {
    try {
      const { data } = await api.order.getOrderDetailsDelivery(id);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const assignOrder = createAsyncThunk(
  'order/assignOrder',
  async ({ orderId, courierId }) => {
    try {
      const { data } = await api.order.assignOrder({ orderId, courierId });
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const createOrder = createAsyncThunk(
  'order/createOrder',
  async ({ formData }) => {
    try {
      const { data } = await api.order.createOrder(formData);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const createOrderDelivery = createAsyncThunk(
  'order/createOrderDelivery',
  async ({ formData }) => {
    try {
      const { data } = await api.order.createOrderDelivery(formData);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const deleteOrder = createAsyncThunk(
  'order/deleteOrder',
  async ({ orderId }) => {
    try {
      const { data } = await api.order.deleteOrder(orderId);
      toast.success(data.message);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const cancelOrder = createAsyncThunk(
  'order/cancelOrder',
  async ({ orderId }) => {
    try {
      const { data } = await api.order.cancelOrder(orderId, {
        status: 'notDelivered',
      });
      return data.status;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getAllMerchant = createAsyncThunk('order/getAllMerchant', async () => {
  try {
    const { data } = await api.order.getAllMerchant();
    return data.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const stuartForward = createAsyncThunk('order/stuartForward', async (id) => {
  try {
    const { data } = await api.order.stuartForward(id);
    toast.success('Order has been sent');
    return data.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const uberEatsForward = createAsyncThunk(
  'order/uberEatsForward',
  async (id) => {
    try {
      const { data } = await api.order.uberEatsForward(id);
      toast.success('Order has been sent');
      return data.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const catcherForward = createAsyncThunk('order/catcherForward', async (id) => {
  try {
    const { data } = await api.order.catcherForward(id);
    toast.success('Order has been sent');
    return data.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const getAssignMethod = createAsyncThunk(
  'order/getAssignMethod',
  async (id) => {
    try {
      const { data } = await api.order.getAssignMethod(id);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getOrderDetails,
  getOrderDetailsDelivery,
  assignOrder,
  createOrder,
  createOrderDelivery,
  deleteOrder,
  cancelOrder,
  getAllMerchant,
  stuartForward,
  uberEatsForward,
  catcherForward,
  getAssignMethod,
};

export { thunks };

import { Route, Switch } from 'react-router-dom';
import { lazy, Suspense } from 'react';

import NotFoundPage from 'pages/General/NotFound';
import SignInPage from 'pages/General/Auth/SignInPage';
import { RLoadingOverlay } from 'components/common/RLoadingOverlay';

const UserLayout = lazy(() => import('layouts/UserLayout'));
const DeliveryLayout = lazy(() => import('layouts/DeliveryLayout'));
const Registration = lazy(() => import('pages/General/Registration'));
const Verify = lazy(() => import('pages/General/Verify'));
const CourierTracking = lazy(() => import('pages/User/CourierTracking'));
const PrivacyPolicy = lazy(() => import('./pages/General/PrivacyPolicy'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));

function App() {
  return (
    <Suspense fallback={<RLoadingOverlay isVisible />}>
      <Switch>
        <Route
          path="/privacy-policy"
          component={PrivacyPolicy}
        />
        <Route
          path="/forgot-password"
          component={ForgotPassword}
        />
        <Route
          path="/verify"
          component={Verify}
        />
        <Route
          path="/registration/:role"
          component={Registration}
        />
        <Route
          path="/user"
          component={UserLayout}
        />
        <Route
          path="/delivery"
          component={DeliveryLayout}
        />
        <Route
          path="/courier-tracking"
          component={CourierTracking}
        />
        <Route
          path="/"
          component={SignInPage}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </Suspense>
  );
}

export default App;

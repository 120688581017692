import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import 'react-toastify/dist/ReactToastify.css';
import { Global } from '@emotion/react';
import { BrowserRouter } from 'react-router-dom';

import { GlobalStyles } from 'components/App/GlobalStyled';
import Theme from 'components/App/Theme';
import store from './store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './styles/app.scss';

const persist = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <Global styles={GlobalStyles} />
        <PersistGate
          loading={null}
          persistor={persist}
        >
          <Theme>
            <App />
          </Theme>
        </PersistGate>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          limit={5}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById(`root`)
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
